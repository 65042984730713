import React, { Component } from 'react'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';
import Main from '../../pages/startpage/start';
export default class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: []
        };
      }
      async componentDidMount() 
    {
       window.localStorage.removeItem("IsLoggedin");
       window.location.href ='/';
    }
  
}
