import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './candidate.scss';
import axios from 'axios';
import ReactCrop from 'react-image-crop'
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css';  
export default class Addcandidate extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
        name:'',
        phn:'',
        country:'',
        stateval:'',
        email:'',
        city:'',
        dor:'',
        typeofvisa:'',
        reject_reason:'',
        visatype:'',
        TeamData: [],
        // Initially, no file is selected
      selectedFile: null,
      startDate: new Date()  
    };
    this.handleChangedate = this.handleChangedate.bind(this);  
    
}
componentDidMount() {
  axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteam_add_react.php`).then(response => {
  console.log(response.data);
  this.setState({
  TeamData: response.data
  });
  });
  $("#reject_reason").hide();
  $("#crs_field").hide();
  $("#pnp_field").hide();
  $("#noc_field").hide();
  $("#points_field").hide();
  $("#wage_value").hide();
  }
// On file select (from the pop up)
onFileChange = event => {
     
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
 
};
  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
    const name = this.state.name;
    
    const sname = this.state.spousename;
    const phn = this.state.phn;
    const email =this.state.email;
    const delegateemail =this.state.demail;
    const country = this.state.country;
    const stateval = this.state.stateval;
    const city = this.state.city;
    const dor = $("#startDate").val();
    const visatype= $("#visatype").val();
    //alert(dor);
    const cstatus = this.state.status;
    const typeofvisa =  $("#typeofvisa").val();
    const assignedconsultant =  $("#assignedconsultant").val();
    const rejectreason= this.state.rejectreason;
    const crsfield= this.state.crsfield;
    const pnpfield= this.state.pnpfield;
    const nocfield= this.state.nocfield;
    const pointsfield= this.state.pointsfield;
    const wagetype= $("#wagetype").val();
    const wagevalue =this.state.wagevalue;
      var formData = new FormData();
      formData.append('name', name);
      formData.append('sname', sname);
      formData.append('phn', phn);
      formData.append('email', email);
      formData.append('delegateemail', delegateemail);
      formData.append('country', country);
      formData.append('stateval', stateval);
      formData.append('city', city);
      formData.append('typeofvisa', typeofvisa);
      formData.append('assignedconsultant', assignedconsultant);
      formData.append('cstatus', cstatus);
      formData.append('dor', dor);
      formData.append('visatype',visatype);
      formData.append('rejectreason',rejectreason);
      formData.append('crsfield',crsfield);
      formData.append('pnpfield',pnpfield);
      formData.append('noc',nocfield);
      formData.append('points',pointsfield);
      formData.append('wagetype',wagetype);
      formData.append('wagevalue',wagevalue);
      console.log(this.state.selectedFile);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/insertcandidate_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
    console.log('Success:', data);
      if(data == 'success')
      {
        $('.error').html('Candidate Details Added Sucessfully');
        $('.error').css("color", "green");
        $("#result").focus();
        $('#name').val("");
        $('#spousename').val("");
        $('#typeofvisa').val("");
        $('#phn').val("");
        $('#email').val("");
        $('#demail').val("");
        $('#country').val("");
        $('#stateval').val("");
        $('#city').val("");
        $('#assignedconsultant').val("");
        $('#status').val("");
        $('#visatype').val("");
        $("#startDate").val("");
      }
      else
      {
        $('.error').html('Emailid already exists');
        $('.error').css("color", "red");
        $("#result").focus();
      }
    })

}
handleChange = (event) => {
  this.setState({selectValue: event.target.value});
  const val_visa=$("#typeofvisa").val();
  if(val_visa == 'PR' || val_visa == 'PNP' || val_visa == 'LMIAs' || val_visa == 'Work Permit')
    {
      
      if(val_visa == 'PR')
      {
        $("#crs_field").show();
        $("#crslabel").text('CRS Score');
        $("#noc_field").show();
        $("#points_field").show();
      }
      if(val_visa == 'PNP')
      {
        $("#crs_field").hide();
        $("#pnp_field").show();
        
        $("#noc_field").show();
        $("#points_field").show();
      }
      if(val_visa == 'LMIAs')
      {
        $("#crs_field").hide();
        $("#pnp_field").hide();
        
        $("#noc_field").show();
        $("#points_field").hide();
      }
      if(val_visa == 'Work Permit')
      {
        $("#crs_field").hide();
        $("#pnp_field").hide();
        $("#points_field").hide();
        $("#noc_field").show();
      }
      
    }
    else
    {
      $("#crs_field").hide();
      $("#crsfield").val('');
      $("#pnp_field").hide();
      $("#pnpfield").val('');
      $("#noc_field").hide();
      $("#nocfield").val('');
      $("#points_field").hide();
      $("#pointsfield").val('');
    }
  } 
 handleChange_wage = (event) => {
    
    const val=$("#visatype").val();
    if(val == 2)
    {
      $("#reject_reason").show();
    }
    else
    {
      $("#reject_reason").hide();
      $("#rejectreason").val('');
    }
    
}
handleChange_wage = (event) => {
    
  const val=$("#wagetype").val();
  
    $("#wage_value").show();
  
   
  
}


  handleChangedate(date) {  
    this.setState({  
      startDate: date  
    })  
  }  
   maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }
  render() {
    
    return (
      <div className='addblog'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Add Candidate</h1>
        </div>
        <div className='col-sm-4' id="result" tabindex='1'>
        <p className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="candidates/All">Manage Candidates</a></li>
            <li className="breadcrumb-item active">Add Candidate</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">
        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
            { /*<div className="form-group">
                  <label htmlFor="exampleInputEmail1">Blog Pic<sup className='validation'>*</sup></label>
                  <br/>
                  <input type="file" name="pic"  id="pic"  required  onChange={this.onFileChange}   />
    </div>*/}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Candidate Name<sup className='validation'>*</sup></label>
                  <input type="text" name="name" className="form-control" id="name" placeholder="Enter Name" required onChange={(event)=>{
                        this.setState({
                            name:event.target.value
                        });
                    }} value={this.state.name}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Spouse Name</label>
                  <input type="text" name="spousename" className="form-control" id="spousename" placeholder="Enter Spouse Name"  onChange={(event)=>{
                        this.setState({
                          spousename:event.target.value
                        });
                    }} value={this.state.spousename}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Phone Number</label>
                  <input type="text" name="phn" className="form-control" id="phn" placeholder="Enter Phone Number"  onChange={(event)=>{
                        this.setState({
                            phn:event.target.value
                        });
                    }} value={this.state.phn}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email ID<sup className='validation'>*</sup></label>
                  <input type="text" name="email" className="form-control" id="email" placeholder="Enter Emailid" required  onChange={(event)=>{
                        this.setState({
                            email:event.target.value
                        });
                    }} value={this.state.email}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Delegated Email ID<sup className='validation'>*</sup></label>
                  <input type="text" name="demail" className="form-control" id="demail" placeholder="Enter Emailid"  required onChange={(event)=>{
                        this.setState({
                            demail:event.target.value
                        });
                    }} value={this.state.demail}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Country</label>
                  <input type="text" name="country" className="form-control" id="country" placeholder="Enter Country"  onChange={(event)=>{
                        this.setState({
                            country:event.target.value
                        });
                    }} value={this.state.country}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">State</label>
                  <input type="text" name="stateval" className="form-control" id="stateval" placeholder="Enter State"  onChange={(event)=>{
                        this.setState({
                            stateval:event.target.value
                        });
                    }} value={this.state.stateval}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City</label>
                  <input type="text" name="city" className="form-control" id="city" placeholder="Enter City" onChange={(event)=>{
                        this.setState({
                            city:event.target.value
                        });
                    }} value={this.state.city}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Date Of Registration<sup className='validation'>*</sup></label>
                  <DatePicker  
              selected={ this.state.startDate }  
              onChange={ this.handleChangedate }  
              name="startDate"  id='startDate'
              dateFormat="yyyy-MM-dd"  
              className="form-control" required/>  
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Type Of Visa<sup className='validation'>*</sup></label>
                  <select name="typeofvisa" id="typeofvisa" value={this.state.value} required className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
                      <option value="">Select Visa Type</option>
                      <option value="PR">Permanent Residency</option>
                      <option value="LMIAs">LMIA</option>
                      <option value="Misc">Misc Services</option>
                      <option value="PNP">PNPs/Job Offers/RNIPs</option>
                      <option value="Dependent">SOWP</option>
                      <option value="Student">Student Visa</option>
                      <option value="Super">Super Visa</option>
                      <option value="Visitor">Visitor Visa</option>
                      <option value="Work Permit">Work Permit Visa</option>
                      <option value="Startup">Start-up Visa</option>
                      <option value="Spousal PR">Spousal PR</option>
                    </select>
                </div>
                <div className="form-group" id="crs_field">
                    <label htmlFor="exampleInputPassword1" id="crslabel">CRS Score</label>
                    <input type="number" name="crsfield" className="form-control" id="crsfield" placeholder="Enter CRS Score" maxLength = "4" onInput={this.maxLengthCheck} onChange={(event)=>{
                        this.setState({
                          crsfield:event.target.value
                        });
                    }} value={this.state.crsfield}  />
                    
                </div>
                <div className="form-group" id="pnp_field">
                    <label htmlFor="exampleInputPassword1" id="pnplabel">PNP Scorng Points</label>
                    <input type="number" name="pnpfield" className="form-control" id="pnpfield" placeholder="Enter PNP Points" maxLength = "4" onInput={this.maxLengthCheck} onChange={(event)=>{
                        this.setState({
                          pnpfield:event.target.value
                        });
                    }} value={this.state.pnpfield}  />
                    
                </div>
                <div className="form-group" id="noc_field">
                    <label htmlFor="exampleInputPassword1">NOC Code</label>
                    <input type="number" name="nocfield" className="form-control" id="nocfield" placeholder="Enter NOC Code" maxLength = "5" onInput={this.maxLengthCheck} onChange={(event)=>{
                        this.setState({
                          nocfield:event.target.value
                        });
                    }} value={this.state.nocfield}  />
                    
                </div>
                <div className="form-group" id="points_field">
                    <label htmlFor="exampleInputPassword1">67 Points Calculation</label>
                    <input type="number" name="pointsfield" className="form-control" id="pointsfield" placeholder="Enter 67 Points Calculation" maxLength = "3" onInput={this.maxLengthCheck} onChange={(event)=>{
                        this.setState({
                          pointsfield:event.target.value
                        });
                    }} value={this.state.pointsfield}  />
                    
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Assigned Consultant<sup className='validation'>*</sup></label>
                  
                    <select name="assignedconsultant" id="assignedconsultant"  required value={this.state.value} className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
           <option value="">Select Consultant</option>
           {this.state.TeamData.map((e, key) => {  
return <option key={key} value={e.Teamid}>{e.Name}</option>;  
})}      
                    </select>
                </div>
                <div className="form-group">
                <label htmlFor="exampleInputEmail1">Applicant Type<sup className='validation'>*</sup></label>
                <select name="visatype" id="visatype" value={this.state.value} required className="form-control vformcolor labelstyle" 
        onChange={this.handleChange_visaapproved} >
                      <option value="">Select Visa Type</option>
                      <option value="1">Fresh</option>
                      <option value="2">Rejected</option>
                </select>
                </div>
                <div className="form-group" id="reject_reason">
                    <label htmlFor="exampleInputPassword1">Rejected Reason</label>
                    <textarea className="form-control" name="rejectreason" id="rejectreason"  onChange={(event)=>{
                        this.setState({
                          rejectreason:event.target.value
                        });
                    }} value={this.state.rejectreason} ></textarea>
                </div>
                <div className="form-group">
                <label htmlFor="exampleInputEmail1">Wage</label>
                <select name="wagetype" id="wagetype" value={this.state.value}  className="form-control vformcolor labelstyle" 
        onChange={this.handleChange_wage} >
                      <option value="">Select Wage</option>
                      <option value="Low">Low</option>
                      <option value="Median">Median</option>
                      <option value="High">High</option>
                </select>
                </div>
                <div className="form-group" id="wage_value">
                    <label htmlFor="exampleInputPassword1">Wage Details</label>
                    <input type="text" className="form-control" name="wagevalue" id="wagevalue"  onChange={(event)=>{
                        this.setState({
                          wagevalue:event.target.value
                        });
                    }} value={this.state.wagevalue} />
                </div>
                 <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Current Status<sup className='validation'>*</sup></label>
                    <textarea className="form-control" name="status" id="status" required onChange={(event)=>{
                        this.setState({
                          status:event.target.value
                        });
                    }} value={this.state.status}></textarea>
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        <div className="col-md-2">
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}
