import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './university.scss';
export default class Adduniversity extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
      UniversityName:'',
      DLI:'',
      selectedFile: null
    }
    
}
// On file select (from the pop up)
onFileChange = event => {
     
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
 
};
  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
    const UniversityName = this.state.UniversityName;
    const DLI = this.state.DLI;
    const UniversityRank = this.state.UniversityRank;
    const InstituteType = this.state.InstituteType;
    const TiedUp = this.state.TiedUp;
    const PGWP = this.state.PGWP;
    const Description = this.state.Description;
    const file =this.state.selectedFile;
    const filename='';
    if(file)
    {
       filename = this.state.selectedFile.name;
    }
       
    
    
    const bodyval = {
      UniversityName,
      DLI,
      UniversityRank,
      InstituteType,
      TiedUp,
      PGWP,
      Description
      };
      var formData = new FormData();
      formData.append('UniversityName', UniversityName);
      formData.append('DLI', DLI);
      formData.append('UniversityRank', UniversityRank);
      formData.append('InstituteType', InstituteType);
      formData.append('TiedUp', TiedUp);
      formData.append('PGWP', PGWP);
      formData.append('Description', Description);
      formData.append('filen', file);
      formData.append('filename', filename);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/insertuniversity_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('University added successfully');
        $('.error').css("color", "green");
        //$('#linkname').val("");
        //$('#description').val("");
      }
      else if(data == 'exists')
      {
        $('#result').html('University name already exists');
        $('.error').css("color", "red");
      }
    })

}
  render() {
    $(function () {
        // Summernote
        //$('#summernote').summernote();
        CodeMirror.fromTextArea(document.getElementById("summernote"), {
          mode: "htmlmixed",
          theme: "monokai"
        });
      })
    return (
      <div className='adduniversity'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Add Universitiy</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="universities">Manage Universities</a></li>
            <li className="breadcrumb-item active">Add Universitiy</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-3"></div>
        <div className="col-md-6">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <large>Required Fields</large></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
              <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University Pic</label>
                  <br/>
                  <input type="file" name="pic"  id="pic"  onChange={this.onFileChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University Name<sup className='validation'>*</sup></label>
                  <input type="text" name="UniversityName" className="form-control" id="UniversityName" placeholder="Enter University Name" required onChange={(event)=>{
                        this.setState({
                          UniversityName:event.target.value
                        });
                    }} value={this.state.UniversityName}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University DLI</label>
                  <input type="text" name="DLI" className="form-control" id="DLI" placeholder="Enter University DLI" onChange={(event)=>{
                        this.setState({
                          DLI:event.target.value
                        });
                    }} value={this.state.DLI}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University Rank</label>
                  <input type="text" name="UniversityRank" className="form-control" id="UniversityRank" placeholder="Enter University Rank" onChange={(event)=>{
                        this.setState({
                          UniversityRank:event.target.value
                        });
                    }} value={this.state.UniversityRank}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Institute Type<sup className='validation'>*</sup></label>
                <div>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="InstituteType" id="InstituteTypeUniversity" required defaultValue="University"  onChange={(event)=>{
                        this.setState({
                          InstituteType:event.target.value
                        });
                    }} value={this.state.InstituteType} /> University
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="InstituteType" id="InstituteTypeCollege"  required defaultValue="College" onChange={(event)=>{
                        this.setState({
                          InstituteType:event.target.value
                        });
                    }} value={this.state.InstituteType} /> College
                  </label>
                </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Institute Tied Up?<sup className='validation'>*</sup></label>
                <div>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="TiedUp" id="TiedUpYes" defaultValue={1} required  onChange={(event)=>{
                        this.setState({
                          TiedUp:event.target.value
                        });
                    }} value={this.state.TiedUp}/> Yes
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="TiedUp" id="TiedUpNo" defaultValue={0} required onChange={(event)=>{
                        this.setState({
                          TiedUp:event.target.value
                        });
                    }} value={this.state.TiedUp}/> No
                  </label>
                </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Offers PGWP<sup className='validation'>*</sup></label>
                <div>
                  <label className="radio-inline vsmalltextpink col-md-4 nomargin vdisplay-inline">
                    <input type="radio" name="PGWP" required id="PGWPYesDetails" defaultValue="Yes (details)" onChange={(event)=>{
                        this.setState({
                          PGWP:event.target.value
                        });
                    }} value={this.state.PGWP}/> Yes (details)
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="PGWP" required id="PGWPYes" defaultValue="Yes" onChange={(event)=>{
                        this.setState({
                          PGWP:event.target.value
                        });
                    }} value={this.state.PGWP}/> Yes
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="PGWP" id="PGWPNo" defaultValue={0} onChange={(event)=>{
                        this.setState({
                          PGWP:event.target.value
                        });
                    }} value={this.state.PGWP}/> No
                  </label>
                </div>
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description</label>
                    <textarea className="form-control" name="Description" id="Description"  onChange={(event)=>{
                        this.setState({
                          Description:event.target.value
                        });
                    }} value={this.state.Description}></textarea>
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}
