import "./sidebar.scss"

const Sidebar = () => {
  return (
    
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
    <a href="/dashboard" className="brand-link">
      <img src="../../dist/img/gurammor_footer_logo.svg" style={{width:"230px"}} alt="NCPL Logo"  />
      
      
    </a>
    
    <div className="sidebar">
      {/* style={{opacity: '.8'}}className="brand-image img-circle elevation-3"<div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
        </div>
        <div className="info">
          <a href="#" className="d-block">Alexander Pierce</a>
        </div>
      </div>*/}
      <div className="form-inline">
        <div className="input-group" data-widget="sidebar-search">
          <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
          <div className="input-group-append">
            <button className="btn btn-sidebar">
              <i className="fas fa-search fa-fw" />
            </button>
          </div>
        </div>
      </div>
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li className="nav-item menu-open">
            <a href="/dashboard" className="nav-link dashboard">
              <i className="nav-icon fas fa-tachometer-alt" />
              <p>
                Dashboard
                {/*<i className="right fas fa-angle-left" />*/}
              </p>
            </a>
            {/*<ul className="nav nav-treeview">
              <li className="nav-item">
                <a href="./index.html" className="nav-link active">
                  <i className="far fa-circle nav-icon" />
                  <p>Dashboard v1</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="./index2.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Dashboard v2</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="./index3.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Dashboard v3</p>
                </a>
              </li>
            </ul>*/}
          </li>
          <li className="nav-item">
            <a href="/timelinedashboard" className="nav-link timelineval">
              <i className="nav-icon fas fa-tachometer-alt" />
              <p>
                Timeline Dashboard
                {/*<span className="right badge badge-danger">New</span>*/}
              </p>
            </a>
          </li>

          <li className="nav-item">
            <a href="/mapview" className="nav-link geodashboard">
              <i className="nav-icon fas fa-map" />
              <p>
                Geo Dashboard
                {/*<span className="right badge badge-danger">New</span>*/}
              </p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/candidates/All" className="nav-link candidate">
              <i className="nav-icon fas fa-user" />
              <p>
                Candidate
                
              </p>
            </a>
            
          </li>
          <li className="nav-item">
            <a href="/marquee" className="nav-link marquee">
              <i className="nav-icon fas fa-th" />
              <p>
              Marquee
                {/*<span className="right badge badge-danger">New</span>*/}
              </p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/blog" className="nav-link blog">
              <i className="nav-icon fas fa-copy" />
              <p>
                Blog
               {/* <i className="fas fa-angle-left right" />
                <span className="badge badge-info right">6</span>*/}
              </p>
            </a>
            {/*<ul className="nav nav-treeview">
              <li className="nav-item">
                <a href="pages/layout/top-nav.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Top Navigation</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/top-nav-sidebar.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Top Navigation + Sidebar</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/boxed.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Boxed</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/fixed-sidebar.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Fixed Sidebar</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/fixed-sidebar-custom.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Fixed Sidebar <small>+ Custom Area</small></p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/fixed-topnav.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Fixed Navbar</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/fixed-footer.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Fixed Footer</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/layout/collapsed-sidebar.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Collapsed Sidebar</p>
                </a>
              </li>
            </ul>*/}
          </li>
          <li className="nav-item">
            <a href="/universities" className="nav-link university">
              <i className="nav-icon fas fa-university" />
              <p>
              Universities
               
              </p>
            </a>
            </li>
            <li className="nav-item">
            <a href="/team" className="nav-link team">
              <i className="nav-icon fas fa-user" />
              <p>
                Team
                
              </p>
            </a>
            
          </li>
          <li className="nav-item">
            <a href="/users" className="nav-link users">
              <i className="nav-icon fas fa-user" />
              <p>
                Users
                
              </p>
            </a>
            
          </li>
          <li className="nav-item">
            <a href="/courses" className="nav-link course">
              <i className="nav-icon fas fa-book-reader" />
              <p>
                Courses
                
              </p>
            </a>
            
          </li>
          
          {/*<li className="nav-item">
            <a href="/export" className="nav-link course">
              <i className="nav-icon fas fa-book-reader" />
              <p>
                Excel Download
                
              </p>
            </a>
            
          </li>*/}
          
        </ul>
      </nav>
    </div>
  </aside>
    
  )
}

export default Sidebar
