import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './university.scss';
import ReactCrop from 'react-image-crop'
import axios from 'axios';
import withRouter from '../../withRouter';
class EditUniversity extends Component {
   
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
        filenameval:'',
        // Initially, no file is selected
      selectedFile: null
    }
    
}
// On file select (from the pop up)
onFileChange = event => {
     
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
 
};

async componentDidMount() 
    {
        let idvalue = this.props.params.id;
        

        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getuniversityid_react.php?id=${idvalue}`).then(function(response) {
          $('#universityid').val(response.data[0]['UniversityId']);
          $('#UniversityName').val(response.data[0]['UniversityName']);
         $('#Description').val(response.data[0]['Description']);
         $('#DLI').val(response.data[0]['DLI']);
         $('#UniversityRank').val(response.data[0]['UniversityRank']);
         $('#InstituteType').val(response.data[0]['InstituteType']);
         $("input[value='" + response.data[0]['InstituteType'] + "']").prop('checked', true);
         $('#TiedUp').val(response.data[0]['TiedUp']);
         $("input[value='" + response.data[0]['TiedUp'] + "']").prop('checked', true);
         $('#PGWP').val(response.data[0]['PGWP']);
         $("input[value='" + response.data[0]['PGWP'] + "']").prop('checked', true);
         $('#filename').val(response.data[0]['UniversityLogo']);
         if(response.data[0]['UniversityLogo'] == '')
         {
          //$('#blogpic').attr('src',`${process.env.REACT_APP_WEBSERVICE_URL}/uploads/image_icon.png`);
          $('#blogpic').hide();
         }else
         {
          $('#blogpic').attr('src',`${process.env.REACT_APP_WEBSERVICE_URL}/uploads/`+response.data[0]['UniversityLogo']);
         }
    });
    }
  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
   let filenameval='';
    const UniversityName = $('#UniversityName').val();
    const DLI = $('#DLI').val();
    const UniversityRank =$('#UniversityRank').val();
    const InstituteType = $('input[name="InstituteType"]:checked').val();
    const TiedUp = $('input[name="TiedUp"]:checked').val();
    const PGWP =  $('input[name="PGWP"]:checked').val();
    const Description = $('#Description').val();
    /*alert(InstituteType);
    alert(TiedUp);
    alert(PGWP);*/
    const id = $('#universityid').val();
    
      let file =this.state.selectedFile;
      console.log(file);
      if(file === null)
      {
        filenameval = $('#filename').val();
      }
      else
      {
        filenameval = this.state.selectedFile.name;
      }
      var formData = new FormData();
      formData.append('UniversityName', UniversityName);
      formData.append('DLI', DLI);
      formData.append('UniversityRank', UniversityRank);
      formData.append('InstituteType', InstituteType);
      formData.append('TiedUp', TiedUp);
      formData.append('PGWP', PGWP);
      formData.append('Description', Description);
      formData.append('filen', file);
      formData.append('id', id);
      formData.append('filename', filenameval);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/updateuniversity_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('University Updated successfully');
        $('.error').css("color", "green");
        $("#result").focus();
        //$('#linkname').val("");
        //$('#description').val("");
      }
      else if(data == 'fail')
      {
        $('#result').html('Issue with update');
        $('.error').css("color", "red");
        $("#result").focus();
      }
    })

}
handleOnChange(e) {
  console.log('selected option', e.target.value);
  this.setState({ selectedOption: e.target.value});
}
  render() {
    
    return (
      <div className='adduniversity'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Edit Universitiy</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/universities">Manage Universities</a></li>
            <li className="breadcrumb-item active">Edit Universitiy</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-3"></div>
        <div className="col-md-6">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <large>Required Fields</large></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
              <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University Pic</label>
                  <br/>
                  <img id="blogpic" src="" style={{width:"303px",height:"303px"}}></img>
                  <br/>
                  <input type="file" name="pic"  id="pic"  onChange={this.onFileChange} />
                  <input type="hidden" name="filename" id="filename"/>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University Name<sup className='validation'>*</sup></label>
                  <input type="text" name="UniversityName" className="form-control" id="UniversityName" placeholder="Enter University Name" required onChange={(event)=>{
                        this.setState({
                          UniversityName:event.target.value
                        });
                    }} value={this.state.UniversityName}  />
                    <input type="hidden" name="universityid" id="universityid"/>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University DLI</label>
                  <input type="text" name="DLI" className="form-control" id="DLI" placeholder="Enter University DLI" onChange={(event)=>{
                        this.setState({
                          DLI:event.target.value
                        });
                    }} value={this.state.DLI}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University Rank</label>
                  <input type="text" name="UniversityRank" className="form-control" id="UniversityRank" placeholder="Enter University Rank" onChange={(event)=>{
                        this.setState({
                          UniversityRank:event.target.value
                        });
                    }} value={this.state.UniversityRank}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Institute Type<sup className='validation'>*</sup></label>
                <div>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="InstituteType" id="InstituteTypeUniversity" defaultValue="University"  onChange={(event)=>{
                        this.setState({
                          InstituteType:event.target.value
                        });
                    }}   /> University
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="InstituteType" id="InstituteTypeCollege" defaultValue="College" onChange={(event)=>{
                        this.setState({
                          InstituteType:event.target.value
                        });
                    }} /> College
                  </label>
                </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Institute Tied Up?<sup className='validation'>*</sup></label>
                <div>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="TiedUp" id="TiedUpYes" defaultValue="1"  onChange={(event)=>{
                        this.setState({
                          TiedUp:event.target.value
                        });
                    }} /> Yes
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="TiedUp" id="TiedUpNo" defaultValue="0" onChange={(event)=>{
                        this.setState({
                          TiedUp:event.target.value
                        });
                    }} /> No
                  </label>
                </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Offers PGWP<sup className='validation'>*</sup></label>
                <div>
                  <label className="radio-inline vsmalltextpink col-md-4 nomargin vdisplay-inline">
                    <input type="radio" name="PGWP" id="PGWPYesDetails" defaultValue="Yes (details)" onChange={(event)=>{
                        this.setState({
                          PGWP:event.target.value
                        });
                    }} /> Yes (details)
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="PGWP" id="PGWPYes" defaultValue="Yes" onChange={(event)=>{
                        this.setState({
                          PGWP:event.target.value
                        });
                    }} /> Yes
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline">
                    <input type="radio" name="PGWP" id="PGWPNo" defaultValue="0" onChange={(event)=>{
                        this.setState({
                          PGWP:event.target.value
                        });
                    }} /> No
                  </label>
                </div>
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description<sup className='validation'>*</sup></label>
                    <textarea className="form-control" name="Description" id="Description" required onChange={(event)=>{
                        this.setState({
                          Description:event.target.value
                        });
                    }} value={this.state.Description}></textarea>
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}export default withRouter(EditUniversity);
