import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Footer from './Footer';
//import Main from './pages/mainpage/Main';
import Main from './pages/startpage/start';
import Marquee from './pages/marquee/Marquee';
import Addmarquee from './pages/marquee/Addmarquee';
import Updatemarquee from './pages/marquee/Updatemarquee';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useParams
} from "react-router-dom";
import Blog from './pages/blog/Blog';
import Addblog from './pages/blog/Addblog';
import Updateblog from './pages/blog/Updateblog';
import Users from './pages/users/Users';
import View from './pages/users/View';

import Universities from './pages/universities/Universities';
import Adduniversity from './pages/universities/Adduniversity';
import Crop from './pages/blog/Crop';
import Update from './pages/blog/update';
import Team from './pages/team/Team';
import Adduser from './pages/users/adduser';
import Candidates from './pages/candidates/Candidates';
import Addcandidate from './pages/candidates/addcandidate';
import Addteam from './pages/team/addteam';
import Editteam from './pages/team/Updateteam';
import Viewcandidate from './pages/candidates/View';
import Editcandidate from './pages/candidates/editcandidate';
import Logout from './pages/logout/Logout';
import Edituniversity from './pages/universities/Edituniversity';
import Course from './pages/courses/Course';
import Addcourse from './pages/courses/addcourse';
import Importcourse from './pages/courses/importcourse';
import Editcourse from './pages/courses/Editcourse';
import Redirection from './redirection';
import Timelinedashboard from './Timelinedashboard';
import Mapview from './Mapview';
import Export from './pages/export/Export';
function App() {
  const loggedin = window.localStorage.getItem("IsLoggedin");
  return (
    <div class="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route path='/' element={loggedin ? <Dashboard /> : <Main /> } />
            <Route path='dashboard' element= {loggedin ? <Dashboard /> : <Main /> }/>
            <Route path='timelinedashboard' element= {loggedin ? <Timelinedashboard /> : <Main /> }/>
            <Route path='mapview' element= {loggedin ? <Mapview /> : <Main /> }/>
            <Route path='marquee' element={loggedin ? <Marquee /> : <Main /> } />
            <Route path='marquee/:id/edit' element= {loggedin ? <Updatemarquee /> : <Main /> }  />
            <Route path='addmarquee' element= {loggedin ? <Addmarquee /> : <Main /> } />
            <Route path='blog' element= {loggedin ? <Blog /> : <Main /> } />
            <Route path='addblog' element= {loggedin ? <Addblog /> : <Main /> } />
            <Route path='blog/:id/edit' element= {loggedin ? <Update /> : <Main /> }/>
            <Route path='users' element= {loggedin ? <Users /> : <Main /> }/>
            <Route path='adduser' element= {loggedin ? <Adduser /> : <Main /> }/>
            <Route path='team' element= {loggedin ? <Team /> : <Main /> } />
            <Route path='addteam' element= {loggedin ? <Addteam /> : <Main /> } />
            <Route path='team/:id/edit' element= {loggedin ? <Editteam /> : <Main /> } />
            <Route path='universities' element= {loggedin ? <Universities /> : <Main /> } />
            <Route path='adduniversity' element= {loggedin ? <Adduniversity /> : <Main /> } />
            <Route path='universities/:id/edit' element= {loggedin ? <Edituniversity /> : <Main /> } />
            <Route path='users/:id/view' element= {loggedin ? <View /> : <Main /> } />
            <Route path='crop' element= { <Crop /> } />
            <Route path='candidates' element= {loggedin ? <Candidates /> : <Main /> } />
            <Route path="/candidates/:visa" element= {loggedin ? <Candidates /> : <Main /> } />
            <Route path="/candidates/:visa/:consultant/:status" element= {loggedin ? <Candidates /> : <Main /> } />
            <Route path="/candidates/:visa/:consultant" element= {loggedin ? <Candidates /> : <Main /> } />
            <Route path='addcandidate' element= {loggedin ? <Addcandidate /> : <Main /> }  />
            <Route path='candidates/:id/view' element= {loggedin ? <Viewcandidate /> : <Main /> } />
            <Route path='candidates/:id/edit' element= {loggedin ? <Editcandidate /> : <Main /> } />
            <Route path='courses' element= {loggedin ? <Course /> : <Main /> } />
            <Route path='addcourse' element= {loggedin ? <Addcourse /> : <Main /> } />
            <Route path='courses/:id/edit' element= {loggedin ? <Editcourse /> : <Main /> } />
            <Route path='importcourse' element= {loggedin ? <Importcourse /> : <Main /> } />
            <Route path='logout' element= { <Logout /> } />
            <Route path='redirection' element= { <Redirection /> } />
            <Route path='export' element= {loggedin ? <Export /> : <Main /> } />
          </Route>
        </Routes>
      </BrowserRouter>
      
      
    </div>
  );
}

export default App;
