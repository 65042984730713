import React, { Component } from 'react'
import axios from 'axios';
import $ from 'jquery';
import Marquee from './pages/marquee/Marquee';
export default class Redirection extends Component {
    async componentDidMount() 
    {
       
    }
    render() {
        $(function () {
           // alert('redirect');
           window.location.href='Marquee';
          });
          return (
            <div>
               
            </div>
          )
    }
}