import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  
export default function ListUser() {
    const navigate = useNavigate();
    const TeamData =[];
    const [inputs, setInputs] = useState([]);
    const [stateOptions, setStateValues] = useState([]);  
    let rejectreasonval='';
    const {id} = useParams();
    let selval;
    let profilestatus_val;
    let visatype_val;
    let reject_val;
    let responsedate;
    let regdate;
    const startDate= new Date()  ;
    const [edit_check_date, set_edit_check_date] = useState();
    const [edit_reg_date, set_edit_reg_date] = useState();
    useEffect(() => {
        getUser();
        //getTeam();
        /*hideapproveddate_row();*/
    }, []);
    function getUser() {
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcandidateid_react.php?id=${id}`).then(function(response) {
           // console.log(response);
            console.log(response.data[0]['VisaType']);
            profilestatus_val=response.data[0]['Status'];
            visatype_val=response.data[0]['VisaType'];
            reject_val=response.data[0]['Reject_reason'];
            setInputs(response.data);
             $('#name').val(response.data[0]['Name']);
             $('#candidateid').val(response.data[0]['candidateid']);
             $('#spousename').val(response.data[0]['Sname']);
             $('#email').val(response.data[0]['Email']);
             $('#demail').val(response.data[0]['DelegateEmailid']);
             $('#phn').val(response.data[0]['Mobile']);
             $('#country').val(response.data[0]['Country']);
             $('#stateval').val(response.data[0]['State']);
             $('#city').val(response.data[0]['City']);
             $('#profile_status').val(response.data[0]['Status']);
             $('#wagevalue').val(response.data[0]['Wagevalue']);
             $('#wagetype').val(response.data[0]['WageType']);
             if(response.data[0]['Typeofvisa'] == 'PR'|| response.data[0]['Typeofvisa']=='PNP' || response.data[0]['Typeofvisa'] == 'LMIAs'|| response.data[0]['Typeofvisa']=='Work Permit')
             {
              
              
              if(response.data[0]['Typeofvisa'] == 'PR')
              {
                $("#crs_field").show();
                $("#crslabel").text('CRS Score');
                $("#noc_field").show();
                $("#pnp_field").hide();
                $("#points_field").show();
              }
              if(response.data[0]['Typeofvisa'] == 'PNP')
              {
                $("#pnp_field").show();
                $("#crs_field").hide();
                $("#pnplabel").text('PNP Scorng Points');
                $("#noc_field").show();
                $("#points_field").show();
              }
                      if(response.data[0]['Typeofvisa'] == 'LMIAs')
              {
                $("#crs_field").hide();
                $("#pnp_field").hide();
                
                $("#noc_field").show();
                $("#points_field").hide();
              }
              if(response.data[0]['Typeofvisa'] == 'Work Permit')
              {
                $("#crs_field").hide();
                $("#pnp_field").hide();
                $("#points_field").hide();
                $("#noc_field").show();
              }
             }
             else
             {
              $("#crs_field").hide();
              $("#crsfield").val('');
              $("#pnp_field").hide();
              $("#pnpfield").val('');
              $("#noc_field").hide();
              $("#nocfield").val('');
              $("#points_field").hide();
              $("#pointsfield").val('');
             }
             $('#typeofvisa').val(response.data[0]['Typeofvisa']);
             $('#crsfield').val(response.data[0]['score']);
             $('#nocfield').val(response.data[0]['noc']);
             $('#pointsfield').val(response.data[0]['points']);
             $('#assignedconsultant').val(response.data[0]['Consultantnumber']);
             $('#status').val(response.data[0]['cstatus']);
             $('#sdate').val(response.data[0]['dor']);
             regdate=response.data[0]['dor'];
             responsedate=response.data[0]['result_date'];
             console.log(responsedate);
             if(responsedate) 
             {
              
              var redate = new Date(responsedate); 
              set_edit_check_date(redate)
             }
             else
             {
             
              var redate = ''; 
              set_edit_check_date(redate)
             }
             if(regdate) 
             {
              
              var reggdate = new Date(regdate); 
              set_edit_reg_date(reggdate)
             }
             else
             {
             
              var reggdate = ''; 
              set_edit_reg_date(reggdate)
             }
             
             
            
              selval=response.data[0]['Consultantnumber'];
              if(profilestatus_val == 2 || profilestatus_val ==3 || profilestatus_val==4 || profilestatus_val==5 || profilestatus_val==6 || profilestatus_val==7)
              {
                  if(profilestatus_val == 2 )
                  $("#date_insert").text("Visa Approved Date");
                else if(profilestatus_val == 3 )
                  $("#date_insert").text("Customer Quit Date");
                else if(profilestatus_val == 4 )
                  $("#date_insert").text("Profile On Hold Date");
                else if(profilestatus_val == 5 )
                  $("#date_insert").text("Visa Rejected Date");
                  else if(profilestatus_val == 6 )
                  $("#date_insert").text("Submitted Date");
                  else if(profilestatus_val == 7 )
                  $("#date_insert").text("ITA Received Date");
                $("#visa_aaproveddaterow").show();
                $("#visa_aaproveddaterow").val();
              }
              else
              {
                $("#visa_aaproveddaterow").hide();
              }
              if(visatype_val == 2)
              {
              //  alert(visatype_val);
                $("#reject_reason").show();
                $("#rejectreason").val(reject_val);

              }
              else{
                $("#reject_reason").hide();
                $("#rejectreason").val('');
              }
              axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteam_id_react.php?teamid=${selval}`).then(function(res) {
                console.log(res.data);
                $("#teamid").html(res.data);
                //TeamData= response.data
                //setpdsummaryState(res.data);
               // setStateValues(res.data);
                //console.log(pdsummaryState);    
            });
            axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisa_type_react.php?cid=${id}`).then(function(res) {
              console.log(res.data);
              $("#visaid").html(res.data);
               
          });
          axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getwage_type_react.php?cid=${id}`).then(function(res) {
            console.log(res.data);
            $("#wageid").html(res.data);
             
        });
        });
        
    }
    
    const maxLengthCheck = (object) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
      }
  
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const handleChangevisa = (event) => {
      
  const val_visa=$("#typeofvisa").val();
  if(val_visa == 'PR' || val_visa == 'PNP' || val_visa == 'LMIAs' || val_visa == 'Work Permit')
    {
      
      if(val_visa == 'PR')
      {
        $("#crs_field").show();
        $("#crslabel").text('CRS Score');
        $("#noc_field").show();
        $("#points_field").show();
      }
      if(val_visa == 'PNP')
      {
        $("#crs_field").hide();
        $("#pnp_field").show();
        $("#points_field").show();
        $("#noc_field").show();
      }
      if(val_visa == 'LMIAs')
      {
        $("#crs_field").hide();
        $("#pnp_field").hide();
        
        $("#noc_field").show();
        $("#points_field").hide();
      }
      if(val_visa == 'Work Permit')
      {
        $("#crs_field").hide();
        $("#pnp_field").hide();
        $("#points_field").hide();
        $("#noc_field").show();
      }
      
    }
    else
    {
      $("#crs_field").hide();
      $("#crsfield").val('');
      $("#pnp_field").hide();
      $("#pnpfield").val('');
      $("#noc_field").hide();
      $("#nocfield").val('');
      $("#points_field").hide();
      $("#pointsfield").val('');
    }
  }
    const handleChange_visaapproved = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}));
      const val=$("#profile_status").val();
      if(val == 2 || val ==3 || val==4 || val==5 ||val==6 ||val==7)
      {
        if(val == 2 )
        {
          //alert(2);
          $("#date_insert").text("Visa Approved Date");
        }
        
        else if(val == 3 )
        {
          //alert(3);
          $("#date_insert").text("Customer Quit Date");
        }
       
        else if(val == 4 )
        {
          //alert(4);
          $("#date_insert").text("Profile On Hold Date");
        }
        
        else if(val == 5 )
        {
          //alert(5);
          $("#date_insert").text("Visa Rejected Date");
        }
        else if(val == 6 )
        {
          //alert(5);
          $("#date_insert").text("Submitted Date");
        }
        else if(val == 7 )
        {
          //alert(7);
          $("#date_insert").text("ITA Received Date");
        }

        $("#visa_aaproveddaterow").show();
      }
      else
      {
        $("#visa_aaproveddaterow").hide();
      }
      
  }

  
 const handleChangedate =(event) =>{  
    
  const name = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [name]: value}));
     
  }  
  
    const submitHandler = async (event) => {
        event.preventDefault();
   //console.log(this.state.linkname);
   // console.log(this.state.description);
    const name = $('#name').val();
    const spousename = $('#spousename').val();
    const country = $('#country').val();
    const phn = $('#phn').val();
    const email = $('#email').val();
    const demail = $('#demail').val();
    const stateval = $('#stateval').val();
    const city = $('#city').val();
    const typeofvisa = $('#typeofvisa').val();
    const crsfield = $('#crsfield').val();
    const pnpfield= $('#pnpfield').val();
    const nocfield = $('#nocfield').val();
    const pointsfield = $('#pointsfield').val();
    const profile_status = $('#profile_status').val();
    const status = $('#status').val();
    const idval = $('#candidateid').val();
    const assignedconsultant = $('#assignedconsultant').val();
    const visatypeval = $('#visatypeval').val();
    const regdate = $('#sdate').val();
    const visadate = $('#resultdate').val();
    const wagevalue = $('#wagevalue').val();
    const wagetype = $('#wagetype').val();
    if(visatypeval == 2)
    {
       rejectreasonval=$('#rejectreason').val();
    }
    else
    {
      rejectreasonval=$('#rejectreason').val('');
    }
    if(profile_status == 2 || profile_status ==3 || profile_status==4 || profile_status==5 || profile_status==6)
      {
        if(visadate == '')
        {
          alert('Please Enter the Date');
          $('#resultdate').focus();
        }
        else
        {
          console.log(idval);
    var formData = new FormData();
      formData.append('name', name);
      formData.append('spousename', spousename);
      formData.append('country', country);
      formData.append('phn', phn);
      formData.append('email', email);
      formData.append('demail', demail);
      formData.append('stateval', stateval);
      formData.append('city', city);
      formData.append('typeofvisa', typeofvisa);
      formData.append('crsfield', crsfield);
      formData.append('pnpfield',pnpfield);
      formData.append('nocfield', nocfield);
      formData.append('pointsfield', pointsfield);
      formData.append('profile_status', profile_status);
      formData.append('status', status);
      formData.append('id', idval);
      formData.append('assignedconsultant', assignedconsultant);
      formData.append('visatypeval', visatypeval);
      formData.append('rejectreasonval', rejectreasonval);
      formData.append('visadate', visadate);
      formData.append('regdate', regdate);
      formData.append('wagetype', wagetype);
      formData.append('wagevalue', wagevalue);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/updatecandidate_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }).then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('.error').html('Candidate Details Updated Sucessfully');
        $('.error').css("color", "green");
        
        $("#result").focus();
        //$('#description').val("");
      }
      else
      {
        $('.error').html('Please check the link and description');
        $('.error').css("color", "red");
        $("#result").focus();
      }
    })
        }
      }
      else
      {
        console.log(idval);
    var formData = new FormData();
      formData.append('name', name);
      formData.append('spousename', spousename);
      formData.append('country', country);
      formData.append('phn', phn);
      formData.append('email', email);
      formData.append('demail', demail);
      formData.append('stateval', stateval);
      formData.append('city', city);
      formData.append('typeofvisa', typeofvisa);
      formData.append('crsfield', crsfield);
      formData.append('pnpfield',pnpfield);
      formData.append('nocfield', nocfield);
      formData.append('pointsfield', pointsfield);
      formData.append('profile_status', profile_status);
      formData.append('status', status);
      formData.append('id', idval);
      formData.append('assignedconsultant', assignedconsultant);
      formData.append('visatypeval', visatypeval);
      formData.append('rejectreasonval', rejectreasonval);
      formData.append('visadate', visadate);
      formData.append('regdate', regdate);
      formData.append('wagetype', wagetype);
      formData.append('wagevalue', wagevalue);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/updatecandidate_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }).then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        const clearCacheData = () => {
          caches.keys().then((names) => {
              names.forEach((name) => {
                  caches.delete(name);
              });
          });
        };
        $('.error').html('Candidate Details Updated Sucessfully');
        $('.error').css("color", "green");
        
        $("#result").focus();
        //$('#description').val("");
      }
      else
      {
        $('.error').html('Please check the link and description');
        $('.error').css("color", "red");
        $("#result").focus();
      }
    })
      }
    
       /* axios.put(`http://localhost/immigrations_azure/admin/updatemarquee_react.php?id=${id}`, inputs).then(function(response){
            console.log(response.data);
            navigate('/');
        });*/
        
    }
    return (
      <div className='addblog'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Edit Candidate</h1>
        </div>
        <div className='col-sm-4' id="result" tabindex='1'>
        <p className="error" ></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/candidates/All">Manage Candidates</a></li>
            <li className="breadcrumb-item active">Edit Candidate</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">
        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={submitHandler}>
              <div className="card-body">
            { /*<div className="form-group">
                  <label htmlFor="exampleInputEmail1">Blog Pic<sup className='validation'>*</sup></label>
                  <br/>
                  <input type="file" name="pic"  id="pic"  required  onChange={this.onFileChange}   />
    </div>*/}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Candidate Name<sup className='validation'>*</sup></label>
                  <input type="text" name="name" className="form-control" id="name" placeholder="Enter Name" required onChange={handleChange}   />
                  <input type="hidden" name="candidateid" id="candidateid" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Spouse Name</label>
                  <input type="text" name="spousename" className="form-control" id="spousename" placeholder="Enter Spouse Name"  onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Phone Number</label>
                  <input type="text" name="phn" className="form-control" id="phn" placeholder="Enter Phone Number"  onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email ID</label>
                  <input type="text" name="email" className="form-control" id="email" placeholder="Enter Emailid" onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Delegated Email ID</label>
                  <input type="text" name="demail" className="form-control" id="demail" placeholder="Enter Delegated Emailid" onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Country</label>
                  <input type="text" name="country" className="form-control" id="country" placeholder="Enter Country"  onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">State</label>
                  <input type="text" name="stateval" className="form-control" id="stateval" placeholder="Enter State"  onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City</label>
                  <input type="text" name="city" className="form-control" id="city" placeholder="Enter City" onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Date Of Registration<sup className='validation'>*</sup></label>
                  <DatePicker  id="sdate" name="sdate" selected={edit_reg_date}
                   onChange={date => {set_edit_reg_date(date);}} 
              dateFormat="yyyy-MM-dd"  
              className="form-control" />  
                  {/*<input type="text" name="sdate" className="form-control" id="sdate"  placeholder="Registration Date"    />*/}
                  
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Type Of Visa<sup className='validation'>*</sup></label>
                  <select name="typeofvisa" id="typeofvisa" className="form-control vformcolor labelstyle" 
        onChange={handleChangevisa} >
                      <option value="">Select Visa Type</option>
                      <option value="PR">Permanent Residency Visa</option>
                      <option value="Work Permit">Work Permit Visa</option>
                      <option value="Visitor">Visitor Visa</option>
                      <option value="Super">Super Visa</option>
                      <option value="Student">Student Visa</option>
                      <option value="Dependent">SOWP</option>
                      <option value="PNP">PNPs/Job Offers/RNIPs</option>
                      <option value="LMIAs">LMIA</option>
                      <option value="Misc">Misc Services</option>
                      <option value="Startup">Start-up Visa</option>
                      <option value="Spousal PR">Spousal PR</option>
                    </select>
                </div>
                <div className="form-group" id="crs_field">
                    <label htmlFor="exampleInputPassword1" id="crslabel">CRS Score</label>
                    <input type="number" name="crsfield" className="form-control" id="crsfield" placeholder="Enter CRS Score" maxLength = "4" onInput={maxLengthCheck}  onChange={handleChange}  />
                    
                    
                </div>
                <div className="form-group" id="pnp_field">
                    <label htmlFor="exampleInputPassword1" id="pnplabel">PNP Scorng Points</label>
                    <input type="text" name="pnpfield" className="form-control" id="pnpfield" placeholder="Enter PNP Points" maxLength = "4" onInput={maxLengthCheck} onChange={handleChange}  />
                    
                </div>
                <div className="form-group" id="noc_field">
                    <label htmlFor="exampleInputPassword1">NOC Code</label>
                    <input type="number" name="nocfield" className="form-control" id="nocfield" placeholder="Enter NOC Code" maxLength = "5" onInput={maxLengthCheck}   onChange={handleChange} />
                    
                </div>
                <div className="form-group" id="points_field">
                    <label htmlFor="exampleInputPassword1">67 Points Calculation</label>
                    <input type="number" name="pointsfield" className="form-control" id="pointsfield" placeholder="Enter points" maxLength = "3" onInput={maxLengthCheck}   onChange={handleChange} />
                    
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputEmail1">Assigned Consultant<sup className='validation'>*</sup></label>
                  
                    <select name="assignedconsultant" id="assignedconsultant"  required value={this.state.value} className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
           <option value="">Select Consultant</option>
           {TeamData.map((e, key) => {  
return <option key={key} value={e.Teamid} defaultValue={e.Teamid === selectedCompany}>{e.Name}</option>;  
})}      
                    </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Assigned Consultant<sup className='validation'>*</sup></label>
                  
                    <select name="assignedconsultant" id="assignedconsultant"  required value={this.state.value} className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
           <option value="">Select Consultant</option>
           {this.state.TeamData.map((e, key) => {  
return <option key={key} value={e.Teamid}>{e.Name}</option>;  
})}      
                    </select>
</div>*/}
                 <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Profile Status<sup className='validation'>*</sup></label>
                    <select name="profile_status" id="profile_status" required  className="form-control vformcolor labelstyle" 
        onChange={handleChange_visaapproved} >
                      <option value="">Select Profile Status</option>
                      <option value="1">InProgress</option>
                      <option value="2">Visa Approved & Closed</option>
                      <option value="5">Visa Rejected</option>
                      <option value="3">Customer Quit</option>
                      <option value='6'>Submitted/Awaiting Decision</option>  
                      <option value="4">On Hold</option>
                      {/*<option value="7">ITA Received </option>*/}
                    </select>
                </div>
                <div className="form-group" id="visa_aaproveddaterow">
                  <label id="date_insert"></label>
                  {/*<input type="text" name="vdate" className="form-control" id="vdate"  placeholder="Visa Approved Date"    />*/}
                  <DatePicker  id="resultdate" name="resultdate" selected={edit_check_date}
                   onChange={date => {set_edit_check_date(date);}} 
              dateFormat="yyyy-MM-dd"  
              className="form-control" />  
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Assigned Consultant<sup className='validation'>*</sup></label>
                  <div id="teamid"></div>
                    {/*<select name="assignedconsultant" id="assignedconsultant"  required  className="form-control vformcolor labelstyle" 
        onChange={handleChange} >
           <option value="">Select Consultant</option>
           {stateOptions.map((localState, index) => (
              <option key={index} value={localState.Teamid} defaultValue={selval || 'Select'}>{localState.Name}</option>
            ))}
           
                    </select>*/}
                </div>
                <div className="form-group">
                <label htmlFor="exampleInputEmail1">Applicant Type<sup className='validation'>*</sup></label>
                <div id="visaid"></div>
                
                </div>
                <div className="form-group">
                <label htmlFor="exampleInputEmail1">Wage</label>
                <div id="wageid"></div>
                
                </div>
                <div className="form-group" id="wage_value">
                    <label htmlFor="exampleInputPassword1">Wage Details</label>
                    <input type="text" className="form-control" name="wagevalue" id="wagevalue"  onChange={handleChange} />
                </div>
                <div className="form-group" id="reject_reason">
                    <label htmlFor="exampleInputPassword1">Rejected Reason</label>
                    <textarea className="form-control" name="rejectreason" id="rejectreason"  onChange={handleChange} ></textarea>
                </div>
                 <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Current Status<sup className='validation'>*</sup></label>
                    <textarea className="form-control" name="status" id="status" required onChange={handleChange} ></textarea>
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        <div className="col-md-2">
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>

       
    )
}
