import React, { Component } from 'react'
import { useState } from 'react';
import {  Redirect } from 'react-router';
import $ from 'jquery';
import PropTypes from 'prop-types'
import './main.scss';
export default class login extends Component {
  static propTypes = {
    prop: PropTypes
    
  }
  constructor(props){
    super(props);
    this.state = {
        username:''
    }
}
submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
    const name = this.state.username;
    const pwd = this.state.password;
    const bodyval = {
        name,
        pwd,
      };
      var formData = new FormData();
      formData.append('name', name);
      formData.append('pwd', pwd);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/test.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      window.localStorage.setItem("IsLoggedin",true);
      if(data == 'success')
      {
        window.location.href ='Dashboard';
      }
      else
      {
        $('#result').html(data);
      }
    })

}
  getPHP() {
    var formData = new FormData();
    formData.append('data','saritha');
    fetch('http://localhost/immigrations_azure/admin/test.php', {
        method :'POST',
        //body: JSON.stringify({ data:'saritha'}),
        body : formData,
    }).then (res => res.json())
            .then(response => {
                console.log('response :');
                console.log(response);
            });
}
  render() {
    return (
 
            <>{/*<div>
            <p className="mainText">JUST REACT</p>
            <br />
    <button onClick={this.getPHP}>Load</button>
        </div>*/}
        <div className='login-page'>
        <div className='login'>
        <div className="login-box">
  
  {/* /.login-logo */}
  <div className="card">
    <div className="card-body login-card-body">
    <p id="result" className="error"></p>
    <div className="login-logo">
    <a href="#"><img src="dist/img/gurammor_login_logo.svg" className="elevation" alt="Immigration Image" /></a>
  </div>
      <p className="login-box-msg">Sign in to start your session</p>
      <form action method="post" onSubmit={this.submitHandler}>
        <div className="input-group mb-3">
          <input type="text" className="form-control"  placeholder="Username" id="name" name="name" required onChange={(event)=>{
                        this.setState({
                            username:event.target.value
                        });
                    }} value={this.state.username}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="password" className="form-control" placeholder="Password" id="pwd" name="pwd" required onChange={(event)=>{
                        this.setState({
                            password:event.target.value
                        });
                    }} value={this.state.password} />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="icheck-primary">
              <input type="checkbox" id="remember" />
              <label htmlFor="remember">
                Remember Me
              </label>
            </div>
          </div>
          {/* /.col */}
          <div className="col-4">
            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
          </div>
          {/* /.col */}
        </div>
      </form>
      
      {/* /.social-auth-links */}
      <p className="mb-1">
        <a href="forgot-password.html">I forgot my password</a>
      </p>
      
    </div>
    {/* /.login-card-body */}
  </div>
</div>

        </div>
        </div>
        </>
    )
  }
}
