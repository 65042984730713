import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './course.scss';
import axios from 'axios';
import ReactCrop from 'react-image-crop'
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css';  
export default class Addcourse extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
        name:'',
        phn:'',
        country:'',
        stateval:'',
        email:'',
        city:'',
        dor:'',
        typeofvisa:'',
        countryData: [],
        TeamData: [],
        StudyLevelData: [],
        ProvinceData :[],
        UniversityData:[],
        coursesData :[],
        SubjectTypeData:[],
        // Initially, no file is selected
      selectedFile: null,
      startDate: new Date()  
    };
    this.handleChangedate = this.handleChangedate.bind(this);  
    
}
componentDidMount() {
  axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcountry_react.php`).then(response => {
  console.log(response.data);
  this.setState({
  countryData: response.data
  });
  });
  axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getstudylevel_react.php`).then(response => {
    console.log(response.data);
    this.setState({
    StudyLevelData: response.data
    });
    });
    axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprovince_react.php`).then(response => {
      console.log(response.data);
      this.setState({
        ProvinceData: response.data
      });
      });
      axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getuniversity_react.php`).then(response => {
        console.log(response.data);
        this.setState({
          UniversityData: response.data
        });
        });
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcoursesdropdown_react.php`).then(response => {
          console.log(response.data);
          this.setState({
          coursesData: response.data
          });
          });
          axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getsubjecttypedropdown_react.php`).then(response => {
            console.log(response.data);
            this.setState({
              SubjectTypeData: response.data
            });
            });
  }
// On file select (from the pop up)
onFileChange = event => {
     
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
 
};
  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
    const CourseName = this.state.CourseName;
    const CourseType = this.state.CourseType;
    const SubjectType = this.state.SubjectType;
    const StudyLevel = $("#StudyLevel").val();
    const Country =  $("#Country").val();
    const City =this.state.City;
    const Campus = this.state.Campus;
    const Fees = this.state.Fees;
    const StudyType = this.state.StudyType;
    const Co_op = this.state.Co_op;
    const PTE = this.state.PTE;
    const Intake = this.state.Intake;
    const Backlogs = this.state.Backlogs;
    const IELTS = this.state.IELTS;
    const Province = $("#Province").val();
    const University = $("#University").val();
    const APFee = this.state.APFee;
    const Website = this.state.Website;
    const Internship = this.state.Internship;
    const Format = this.state.Format;
    const TOEFL = this.state.TOEFL;
    const Duolingo = this.state.Duolingo;
    const Duration = this.state.Duration;
    const UniversityTransfer = this.state.UniversityTransfer;
    const AdmissionRequirements = this.state.AdmissionRequirements;

    
    //alert(dor);
    if(UniversityTransfer == 'undefined')
    {
      UniversityTransfer='';
    }
    else if( Campus=='undefined')
    {
      Campus='';
    }
    else if( Duration=='undefined')
    {
      Duration='';
    }
    else if( Fees=='undefined')
    {
      Fees='';
    }
    else if( IELTS=='undefined')
    {
      IELTS='';
    }
    else if( PTE=='undefined')
    {
      PTE='';
    }
    else if( TOEFL=='undefined')
    {
      TOEFL='';
    }else if( APFee=='undefined')
    {
      APFee='';
    }
    else if( Co_op=='undefined')
    {
      Co_op='';
    }
    else if( Format=='undefined')
    {
      Format='';
    }else if( Duolingo=='undefined')
    {
      Duolingo='';
    }
    else if( Internship=='undefined')
    {
      Internship='';
    }
    else if( Backlogs=='undefined')
    {
      Backlogs='';
    }	
    	
				else if( Website=='undefined')
				{
					Website='';
				}
				else if( Intake=='undefined')
				{
					Intake='';
				}
				else if( AdmissionRequirements=='undefined')
				{
					AdmissionRequirements='';
				}
				else if( Internship=='undefined')
				{
					Internship='';
				}
        else if(StudyType == 'undefined')
        {
          StudyType='';
        }
    
    
      var formData = new FormData();
      formData.append('CourseName', CourseName);
      formData.append('CourseType', CourseType);
      formData.append('SubjectType', SubjectType);
      formData.append('StudyLevel', StudyLevel);
      formData.append('Country', Country);
      formData.append('City', City);
      formData.append('Campus', Campus);
      formData.append('Fees', Fees);
      formData.append('StudyType', StudyType);
      formData.append('Co_op', Co_op);
      formData.append('PTE', PTE);
      formData.append('Intake', Intake);
      formData.append('Backlogs', Backlogs);
      formData.append('IELTS', IELTS);
      formData.append('Province', Province);
      formData.append('APFee', APFee);
      formData.append('University', University);
      formData.append('Website', Website);
      formData.append('Internship', Internship);
      formData.append('Format', Format);
      formData.append('TOEFL', TOEFL);
      formData.append('Duolingo', Duolingo);
      formData.append('Duration', Duration);
      formData.append('UniversityTransfer', UniversityTransfer);
      formData.append('AdmissionRequirements', AdmissionRequirements);
      console.log(this.state.selectedFile);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/insertcourse_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
    console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('Course Details Added Sucessfully');
        $('.error').css("color", "green");
        $('#CourseName').val("");
        $('#CourseType').val("");
        $('#SubjectType').val("");
        $('#StudyLevel').val("");
        $('#Country').val("");
        $('#City').val("");
        $('#Campus').val("");
        $('#Fees').val("");
        $('#StudyType').val("");
        $('#Co_op').val("");
        $("#PTE").val("");
        $("#Intake").val("");
        $("#Backlogs").val("");
        $("#IELTS").val("");
        $("#Province").val("");
        $("#Internship").val("");
        $("#APFee").val("");
        $("#TOEFL").val("");
        $("#University").val("");
        $("#Format").val("");
        $("#Website").val("");
        $("#Duolingo").val("");
        $("#Duration").val("");
        $("#UniversityTransfer").val("");
        $("#AdmissionRequirements").val("");
      }
      else
      {
        $('#result').html('Insertion Failed');
        $('.error').css("color", "red");
      }
    })

}
handleChange = (event) => {
  this.setState({selectValue: event.target.value});
  } 
  handleChangedate(date) {  
    this.setState({  
      startDate: date  
    })  
  }  
  render() {
    
    return (
      <div className='addcourse'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Add Course</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="courses">Manage Courses</a></li>
            <li className="breadcrumb-item active">Add Course</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
       
        <div className="col-md-12">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
           
     <div className="row">
            <div className="col-md-6">
            <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Course Name<sup className='validation'>*</sup></label>
                  <input type="text" name="CourseName" className="form-control" id="CourseName" placeholder="Enter Course Name" required onChange={(event)=>{
                        this.setState({
                          CourseName:event.target.value
                        });
                    }} value={this.state.CourseName}  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Study Field<sup className='validation'>*</sup></label>
                  <select className="form-control vformcolor" name="CourseType" id="CourseType" required>
                  <option value="">Select Course</option>
                  {this.state.coursesData.map((e, key) => {  
                  return <option key={key} value={e.CourseType}>{e.CourseType}</option>;  
                  })}      
                </select>
                 
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Country<sup className='validation'>*</sup></label>
               <select className="form-control vformcolor" name="Country" id="Country" required="true">
               <option value="">Select Country</option>
           {this.state.countryData.map((e, key) => {  
return <option key={key} value={e.CountryId}>{e.Country}</option>;  
})}      
                </select>

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City<sup className='validation'>*</sup></label>
                  <input type="text" name="City" className="form-control" id="City" placeholder="Enter City" required onChange={(event)=>{
                        this.setState({
                          City:event.target.value
                        });
                    }} value={this.state.City}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Campus</label>
                  <input type="text" name="Campus" className="form-control" id="Campus"   onChange={(event)=>{
                        this.setState({
                          Campus:event.target.value
                        });
                    }} value={this.state.Campus}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Tution Fees ($)</label>
                  <input type="number" name="Fees" className="form-control" id="Fees"  onChange={(event)=>{
                        this.setState({
                          Fees:event.target.value
                        });
                    }} value={this.state.Fees}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Delivery</label>
                  <input type="text" name="StudyType" className="form-control" id="StudyType"  onChange={(event)=>{
                        this.setState({
                          StudyType:event.target.value
                        });
                    }} value={this.state.StudyType}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Co_op</label>
                  <input type="text" name="Co_op" className="form-control" id="Co_op" onChange={(event)=>{
                        this.setState({
                          Co_op:event.target.value
                        });
                    }} value={this.state.Co_op}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">IELTS</label>
                  <input type="text" name="IELTS" className="form-control" id="IELTS" onChange={(event)=>{
                        this.setState({
                          IELTS:event.target.value
                        });
                    }} value={this.state.IELTS}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">PTE</label>
                  <input type="text" name="PTE" className="form-control" id="PTE" onChange={(event)=>{
                        this.setState({
                          PTE:event.target.value
                        });
                    }} value={this.state.PTE}  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Intake</label>
                  <input type="text" name="Intake" className="form-control" id="Intake" onChange={(event)=>{
                        this.setState({
                          Intake:event.target.value
                        });
                    }} value={this.state.Intake}  />
                    
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Backlogs</label>
                  <input type="text" name="Backlogs" className="form-control" id="Backlogs"  onChange={(event)=>{
                        this.setState({
                          Backlogs:event.target.value
                        });
                    }} value={this.state.Backlogs}  />
                </div>
                 
            </div>

            <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Study Field<sup className='validation'>*</sup></label>
                <select className="form-control vformcolor" name="StudyLevel" id="StudyLevel" required>
                  <option value="">Select Study Level</option>
                  {this.state.StudyLevelData.map((e, key) => {  
return <option key={key} value={e.StudyLevelId}>{e.StudyLevel}</option>;  
})}      

                </select>

                
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Study Subject<sup className='validation'>*</sup></label>
                  <select className="form-control vformcolor" name="SubjectType" id="SubjectType" required>
                  <option value="">Select SubjectType</option>
                  {this.state.SubjectTypeData.map((e, key) => {  
                  return <option key={key} value={e.SubjectType}>{e.SubjectType}</option>;  
                  })}      
                </select>
                 
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Province<sup className='validation'>*</sup></label>
                  <select className="form-control vformcolor" name="Province" id="Province" required>
                  <option value="">Select Province</option>
                  {this.state.ProvinceData.map((e, key) => {  
                  return <option key={key} value={e.ProvinceId}>{e.Province}</option>;  
                  })}      
                </select>

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University/College<sup className='validation'>*</sup></label>
                  <select className="form-control vformcolor" name="University" id="University" required>
                    <option value="">Select University</option>
                    {this.state.UniversityData.map((e, key) => {  
                  return <option key={key} value={e.UniversityId}>{e.UniversityName}</option>;  
                  })}    
                  </select>

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Website</label>
                  <input type="text" name="Website" className="form-control" id="Website"   onChange={(event)=>{
                        this.setState({
                          Website:event.target.value
                        });
                    }} value={this.state.Website}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Application Fees</label>
                  <input type="text" name="APFee" className="form-control" id="APFee"  onChange={(event)=>{
                        this.setState({
                          APFee:event.target.value
                        });
                    }} value={this.state.APFee}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Format</label>
                  <input type="text" name="Format" className="form-control" id="Format" onChange={(event)=>{
                        this.setState({
                          Format:event.target.value
                        });
                    }} value={this.state.Format}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Internship</label>
                  <input type="text" name="Internship" className="form-control" id="Internship" onChange={(event)=>{
                        this.setState({
                          Internship:event.target.value
                        });
                    }} value={this.state.Internship}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">TOEFL</label>
                  <input type="text" name="TOEFL" className="form-control" id="TOEFL" onChange={(event)=>{
                        this.setState({
                          TOEFL:event.target.value
                        });
                    }} value={this.state.TOEFL}  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Duolingo</label>
                  
                  <input type="text" name="Duolingo" className="form-control" id="Duolingo" onChange={(event)=>{
                        this.setState({
                          Duolingo:event.target.value
                        });
                    }} value={this.state.Duolingo}  />
           
                    
                </div>
                 <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Duration (in years)</label>
                    <input type="text" name="Duration" className="form-control" id="Duration" onChange={(event)=>{
                        this.setState({
                          Duration:event.target.value
                        });
                    }} value={this.state.Duration}  />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">University/College Transfer</label>
                    <input type="text" name="UniversityTransfer" className="form-control" id="UniversityTransfer" onChange={(event)=>{
                        this.setState({
                          UniversityTransfer:event.target.value
                        });
                    }} value={this.state.UniversityTransfer}  />
                </div>
              </div>
              <div className='row'>
              <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Admission Requirements</label>
                    <textarea className="form-control" name="AdmissionRequirements" id="AdmissionRequirements"  onChange={(event)=>{
                        this.setState({
                          AdmissionRequirements:event.target.value
                        });
                    }} value={this.state.AdmissionRequirements}></textarea>
                </div>
              </div>
              </div>
                
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}
