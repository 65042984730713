import React, { Component } from 'react'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';
import './university.scss';
export default class Universities extends Component {
    constructor(props) {
        super(props);
        this.state = {
          vUniversityName:'',
          selectValue: "",
          data: [],
          serachdata: []
        };
      }
      async componentDidMount() 
    {
      $(".university").addClass("active");
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getuniversities_react.php`).then(res => 
        {
          this.setState({data: res.data});
          $("#universitysearch_table").hide();
          $("#universitymain_table").show();
          $('#universitymain_table').html(res.data);
          $("#universitytable_ajax").DataTable({
            "columnDefs": [
              { "width": "20%", "targets": 1 }
            ],
            "pageLength": 100
          });
        });
    }
    
    Changestatus = async (event) => {
      event.preventDefault();
      const changeId = event.target.dataset.remove;
      const statusvalue = event.target.dataset.status;
      console.log(changeId);
      console.log(statusvalue);
        var formData = new FormData();
        formData.append('id', changeId);
        formData.append('status', statusvalue);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/Changeuniversitystatus_react.php`,{
          method:'POST',
          headers: new Headers({
             // 'Content-Type': 'application/x-www-form-urlencoded',
     }),
     body: formData,
      }) .then((response) => response.json())
      .then((data) => {
        
        if(data == 'success')
        {
          alert('Status Updated Sucessfully');
          window.location.href ='Universities';
          //$('#result').html('Status Updated Sucessfully');
         // $('.error').css("color", "green");
          
        }
        else
        {
          $('#result').html('Update failed');
          $('.error').css("color", "red");
        }
      })
      
  }
  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
    console.log(this.state.vDLIType);
    const vUniversityName = this.state.vUniversityName;
    const vDLIType =  $("#vDLIType").val();
    const vPGWP = $("#vPGWP").val();;
    const vTiedUp = $("#vTiedUp").val();;
      var formData = new FormData();
      formData.append('UniversityName', vUniversityName);
      formData.append('DLIType', vDLIType);
      formData.append('TiedUp', vTiedUp);
      formData.append('PGWP', vPGWP);
      
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getuniversitysearchdata_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }).then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
      //this.setState({serachdata: data});
      $("#universitymain_table").hide();
      $("#universitytable_wrapper").hide();
      $("#universitysearch_table").show();
      $("#universitysearch_table").html(data);
      $("#searchuniversitytable_ajax").DataTable({
        "columnDefs": [
          { "width": "20%", "targets": 1 }
        ],
        "pageLength": 100
      });
    })

}
Clearfilters = (event) =>{
  document.getElementById("quickForm").reset();
}
handleChange = (event) => {
  this.setState({selectValue: event.target.value});
  } 
  render() {
   // const { hits } = this.state;  
   // console.log(data)
   
    return (
     <div className='university'>
      <Header />
      <Sidebar/>
      
    <div className='content-wrapper'>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Manage Universities / Colleges</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
            <li className="breadcrumb-item active">Universities</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className='fright'>
        <h3 className="card-title"><a href='../adduniversity'><button className='btn btn-info'>Add University</button></a></h3>
        </div>
      
        <div className="col-12">
          <div className="card">
          <form id="quickForm" onSubmit={this.submitHandler}>
            <div className="card-header">
            <p id="result" className="error"></p>
            <div className='row'>
              
                <div className='col-md-3 fleft'>
                
                </div>
              <div className='col-md-6'>
                <div className="form-group row">
                  <div className='col-md-5'>
                  <label htmlFor="exampleInputEmail1">University/College Name</label>
                  </div>
                <div className='col-md-7'>
                    <input type="text" name="vUniversityName" className="form-control labelstyle" id="vUniversityName" placeholder="Enter University Name"  onChange={(event)=>{
                            this.setState({
                              vUniversityName:event.target.value
                            });
                        }} value={this.state.vUniversityName}  />
                </div>
                  
                </div>
                <div className="form-group row">
                  <div className='col-md-5'>
                      <label htmlFor="exampleInputEmail1">DLI Type</label>
                  </div>
                  <div className='col-md-7'>
                    <select name="vDLIType" id="vDLIType" value={this.state.value} className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
                      <option value="">Select DLI Type</option>
                      <option value="College">College</option>
                      <option value="University">University</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-md-5'>
                    <label htmlFor="exampleInputEmail1">PGWP</label>
                  </div>
                  <div className='col-md-7'>
                    <select name="vPGWP" id="vPGWP" class="form-control labelstyle" value={this.state.value}
        onChange={this.handleChange} >
                      <option value="">Select Offers PGWP or not</option>
                      <option value="Yes (details)">Yes (details)</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-md-5'>
                    <label htmlFor="exampleInputEmail1">Tied Up</label>
                  </div>
                  <div className='col-md-7'>
                    <select name="vTiedUp" id="vTiedUp" class="form-control vformcolor labelstyle" value={this.state.value}
        onChange={this.handleChange} >
                      <option value="">Select is Tied up or not</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group fcenter">
                <button type="submit" className="btn btn-primary">Submit</button>&nbsp;
                <a href="/universities"><button type="button" className="btn btn-primary" onClick={this.Clearfilters}>Clear filters</button></a>
                </div>
              </div>
                <div className='col-md-3'>
&nbsp;
                </div>
              
              
              </div>
            </div>
            </form>
            {/* /.card-header */}
            <div className="card-body" >
              <div id="universitysearch_table">

             
            <table id="searchuniversitytable" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>University/College search Name</th>
                    <th>DLI Type</th>
                    <th>Tied Up	</th>
                    <th>PGWP</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.serachdata.map((result) => {
            return (
                 <tr>
                  <td>{result.UniversityId}</td>
                  <td>{result.UniversityName}</td>
                  <td>{result.InstituteType}</td>
                  <td>{result.TiedUp}</td>
                  <td>{result.PGWP}</td>
                  <td>
                                  {(() => {
                        if (result.Status == 'Active') {
                          return (
                            <div className='badge badge-success'>Active</div>
                          )
                        } else if (result.Status == 'Inactive') {
                          return (
                            <div className='badge badge-danger'>Inactive</div>
                          )
                        } else {
                          return (
                            <div>catch all</div>
                          )
                        }
                      })()}
                  </td>
                  <td>
                  <Link to={`${result.UniversityId}/edit`}><button type="button" className="btn btn-primary vminwidth">Update</button></Link>
                  <br/> <br/>
                  <button type="button" className="btn btn-warning vminwidth" onClick={this.Changestatus} data-status={result.Status} data-remove={result.UniversityId}>Change Status</button>
                  
                  </td>
                </tr>
             
            )
          })}
                  
                </tbody>
                <tfoot>
                  <tr>
                    <th>Sno</th>
                    <th>University/College Name</th>
                    <th>DLI Type</th>
                    <th>Tied Up	</th>
                    <th>PGWP</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table> 
              </div>
              <div id="universitymain_table">       
              
              </div> 
            </div>
            {/* /.card-body */}
          </div>
        
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
</div>

   
    </div>

    )
  }
}
