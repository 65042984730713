import React, { Component } from 'react'
import Sidebar from './components/sidebar/Sidebar'
import Header from './components/header/Header'
import axios from 'axios';
import $ from 'jquery';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import CanadaMap from "react-canada-map"
//import ReactDatamaps from "react-india-states-map";
 
//Datatable Modules
import DataTable from 'datatables.net';
import './index.css';
highcharts3d(Highcharts);
let result;
let country;
let newdata;
let newdata_val;
let newdata_bar;
let obj;
let visatype;
let consultant;
let status;
let year;
let count =1;
let title;
let subtitle_val;
export default class Dashboard extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    const year = (new Date()).getFullYear();
    this.years = Array.from(new Array(4),(val, index) => year - index);
    this.state = {
     
          TeamData: [],
          chartvalue: [],
          data: [],
          serachdata: []
    };
    const stateLists = {
      Maharashtra: {
        value: 50,
        content: {
          txt: "Lorem ipsum dolor sit amet consectetur adipisicing elit. A quisquam quae laboriosam sed magni aliquam dolore sequi libero harum, hic nihil. Omnis eos deserunt molestiae harum, cum nemo et temporibus?",
        },
      }
      
    };
   
    
    
    
}
  async componentDidMount() 
    {
      $(".geodashboard").addClass("active");
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/dashboard_react.php`).then(res => 
        {
        //this.setState({data: res.data});
         result = res.data.split('&');
         $('#pr').html(result[0]);
         $('#visitor').html(result[1]);
         $('#student').html(result[2]);
         $('#work').html(result[6]);
         $('#dependant').html(result[4]);
         $('#pnp').html(result[5]);
         $('#lmia').html(result[3]);
         $('#misc').html(result[7]);
         $('#total').html(result[8]);
         $('#active').html(result[9]);
         $('#fail').html(result[10]);
           });
           await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getgeoinfo_react.php`,{
            method:'POST',
            headers: new Headers({
               // 'Content-Type': 'application/x-www-form-urlencoded',
       }),
       body: 'PR',
        }) .then((response) => response.json())
        .then((data) => {
          //console.log(data);
         // var obj = $.parseJSON(data);
		    newdata = $.map(data.year, function(el, index) {
		    return {
				name: el,
				y: parseInt(data['candidatescount'][index]),
        drilldown: parseInt(data['candidatescount'][index])
				};
				});
        this.highChartsRender();
        })
        
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprvisateabledata_react.php`).then(res => 
          {
          //this.setState({serachdata: res.data});
          $('#searchdata_table').html(res.data);
          $("#pr_usertable_data").DataTable({
            "pageLength": 100
          });
             });
             
        
           axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteam_react.php`).then(response => {
            
            this.setState({
            TeamData: response.data
            });
            });
            axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getsuccessrate_react.php`).then(response => {
            
             // alert(response.data);
             const  succ= response.data+'%'
              $("#successval").html(succ);
              });
            /*axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprvisa_react.php`).then(response => {
              console.log(response.data);
              
              
              });*/
            
                 
           
    }
    highChartsRender() {
      
Highcharts.chart('geo-composition', {
  chart: {
      type: 'pie',
      options3d: {
          enabled: true,
          alpha: 45
      }
  },
  title: {
      text: 'Geographical distribution of cases',
      align: 'left'
  },
  subtitle: {
      text: "All Visa's",
      align: 'left'
  },
  plotOptions: {
      pie: {
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            connectorColor: 'silver',
            style: {
              fontSize: '16px',
            
          },
        }
      }
  },
  series: [{
      name: "All Visa's",
      data:newdata      
  }]
});

      /*Highcharts.chart({
        chart: {
          height: 500,
          type: 'pie',
          renderTo: 'geo-composition'
         },
         
       title: {
           text: 'Geographical distribution of  cases'
       },
       tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
       subtitle: {
           text: "All Visa's"
       },
     xAxis: {
                  labels: {
                    style: {
                        fontSize: '16px',
                      
                    }
                },
                  type: 'category',
                       },
       yAxis: {
           title: {
               text: 'Number of Candidates'
           }
       },

       plotOptions: {
        pie: {
          size:400,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                connectorColor: 'silver',
                style: {
                  fontSize: '16px',
                
              },
            }
        }
    },
       series: [{
           name: "All Visa's",
           data:newdata          
       }],

       responsive: {
           rules: [{
               condition: {
                   maxWidth: 500
               },
               chartOptions: {
                   legend: {
                       layout: 'horizontal',
                       align: 'center',
                       verticalAlign: 'bottom'
                   }
               }
           }]
       }
        });*/
    }
    handleChange_country = () => {
      country=$('#country').val();
      //alert(country);
      if(country == 'Canada')
      {
          $("#canadaid").show();
          $("#indiaid").hide();
      }
      else if(country == 'India')
      {
        $("#canadaid").hide();
        $("#indiaid").show();
      }
      else
      {
        $("#canadaid").show();
        $("#indiaid").hide();
      }

    }
    
   
    visaData = async(event) => {
      event.preventDefault();
      this.setState({selectValue: event.target.value});
       visatype=$('#visatype').val();
       consultant=$('#consultant').val();
       status=$('#status').val();
       year=$('#year').val();
       
      var formData = new FormData();
      formData.append('visatype', visatype);
      formData.append('consultant', consultant);
      formData.append('status', status);
      formData.append('year', year);
      //alert(visatype);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getgeosearch_react.php`,{
        method:'POST',
        headers: new Headers({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: formData,
        }) .then((response) => response.json())
          .then((data) => {
          console.log(data);
          if(visatype == 'PR')
          visatype='Express Entry';
          if(visatype =='Dependent')
          visatype='SOWP';
          if(visatype =='Super')
          visatype='Super Visa';
          else
          visatype=visatype+' Visa';
        // var obj = $.parseJSON(data);
        if( visatype!='All')
        {
          title="Year";	
          subtitle_val=visatype;
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
        }
        else if(year !='All' && visatype!='All')
        {
          
          title="Year";		
          subtitle_val="Yearly Performance Report";			
          newdata_val = $.map(data.month, function(el, index) {
							return {
										name: el,
										y: parseInt(data['successcount'][index])
									};
						});
        }
        else if(year !='All' && visatype=='All')
         {
          title="All Visa's";
          subtitle_val="All Visa's";
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
         else if( visatype=='All')
         {
          title="All Visa's";
          subtitle_val="All Visa's";
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
        })
//this.highchartsnew();
Highcharts.chart('geo-composition', {
  chart: {
      type: 'pie',
      options3d: {
          enabled: true,
          alpha: 45
      }
  },
  title: {
      text: 'Geographical distribution of cases',
      align: 'left'
  },
  subtitle: {
      text: subtitle_val,
      align: 'left'
  },
  plotOptions: {
      pie: {
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            connectorColor: 'silver',
            style: {
              fontSize: '16px',
            
          }
        }
      }
  },
  series: [{
      name: "All Visa's",
      data:newdata_val      
  }]
});
            /*Highcharts.chart({
              chart: {
                height: 500,
                type: 'pie',
                renderTo: 'geo-composition'
              },
            title: {
                text: 'Geographical distribution of  cases'
            },

            subtitle: {
                text: subtitle_val
            },
            xAxis: {
                        title: {
                          text: title
                            },
                        type: 'category',
                            },
            yAxis: {
                title: {
                    text: 'Number of Candidates'
                }
            },

            plotOptions: {
              pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                      enabled: true,
                      format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                      connectorColor: 'silver',
                      style: {
                        fontSize: '16px',
                      
                    },
                  }
              }
          },
            series: [{
                name: visatype,
                data:newdata_val          
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
              }
              });*/
             
                

               
                  

                  

      } 
      
       
      
  render() {
    
    return (
      <div>
        <Header />
        <Sidebar />
  <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Geographical distribution of  cases</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Geographical distribution of  cases</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section className="content">
      <div className="container-fluid">
        
        
        <div className='row'>
          <section className="col-lg-12 connectedSortable">
            <div className="card">
                  <div className="card-header">
                  
                    <div className='row'>
                    <div className="col-lg-3">
                          <label>Visa Type</label>
                        <select className='form-control' name='visatype' id='visatype' value={this.state.value} onChange={this.visaData}>
                        <option value="All" selected>All Visas </option>
                          <option value="PR" >Express Entry Visa </option>
                          <option value="LMIAs">LMIA </option>
                          <option value="Misc">Misc Services</option>
                          <option value="PNP">PNPs/Job Offers/RNIPs </option>
                          <option value="Dependent">SOWP</option>
                          <option value="Student">Student Visa </option>
                          <option value="Super">Super Visa </option>
                          <option value="Visitor">Visitor Visa </option>
                          <option value="Work Permit">Work Permit Visa </option>
                          <option value="Startup">Start-up Visa</option>
                          <option value="Spousal PR">Spousal PR</option>
                        </select>
                        </div>
                       {/*} <div className="col-lg-3">
                          <label>Country</label>
                        <select className='form-control' name='country' id='country' value={this.state.value} onChange={this.handleChange_country}>
                        <option value="" >Select Country </option>
                          <option value="Canada" selected>Canada </option>
                          <option value="India">India </option>
                          
                        </select>
                        </div>*/}
                        
                    
    </div>
                  </div>
                 
                 
            </div>
            <div className="card-body">
            <div id="geo-composition"></div>
                {/* <div id="canadaid">
                 <CanadaMap
      customize={this.statesCustomConfig()}
      onclick={this.mapHandler}
      
      />
                 </div>
                 <div id="indiaid">
                
    </div>*/}
                  
                  
                    
                  

                  </div>
          </section>
        </div>
        
      </div>
    </section>
  </div>
</div>

    )
  }
}
