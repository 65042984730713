import React, { Component } from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import './candidate.scss';
import { Link } from "react-router-dom";
export default function ListUser() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const {id} = useParams();
    const [logOptions, setLogValues] = useState([]);  
    useEffect(() => {
        getUser();
        getlog();
        getlogdetails();
    }, []);

    function getUser() {
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcandidateid_react.php?id=${id}`).then(function(response) {
            //console.log(response.data[0]['Description']);
            setInputs(response.data);
            $('#name').html(response.data[0]['Name']);
             $('#visa').html(response.data[0]['Typeofvisa']);
             $('#consultant').html(response.data[0]['Consultant']);
             $('#idval').html(response.data[0]['candidateid']);
             $('#status').html(response.data[0]['cstatus']);
             $('#email').html(response.data[0]['Email']);
             $('#phone').html(response.data[0]['Mobile']);
             $('#state').html(response.data[0]['State']);
             $('#city').html(response.data[0]['City']);
             //alert(response.data[0]['candidateid']);
             $('#candidate_id').val(response.data[0]['candidateid']);
             $('#visa_type').val(response.data[0]['Typeofvisa']);
             $('#consultant_id').val(response.data[0]['Consultantnumber']);
             $('#status_comment').val(response.data[0]['Status']);
        });
    }
    function getlog() {
      axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcandidateid_log.php?id=${id}`).then(function(response) {
          //console.log(response.data[0]['Description']);
          setInputs(response.data);
          setLogValues(response.data);
          
      });
      
  }
  function getlogdetails() {
    axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcandidateid_logdetails.php?id=${id}`).then(function(response) {
        
        $('#candidate_table').html(response.data);
            $("#pr_usertable_data").DataTable(
              {
                "pageLength": 100
              }
            );
           
        
    });
}
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    
    $(function() {
      $("#addcomment").hide();
  });
  const addcomment= (event) =>{
    
    $("#addcomment").show();
    $("#c_name").focus();
    
  }
  const submitHandler = async (event) =>{
    event.preventDefault();
    const c_name = $("#c_name").val();
    const candidate_id =$('#candidate_id').val();
    const visa_type =$('#visa_type').val();
    const consultant_id =$('#consultant_id').val();
    const status_comment =$('#status_comment').val();
    var formData = new FormData();
      formData.append('c_name', c_name);
      formData.append('candidate_id', candidate_id);
      formData.append('visa_type', visa_type);
      formData.append('consultant_id', consultant_id);
      formData.append('status_comment', status_comment);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/insertcandidatecomment_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
    //console.log('Success:', data);
      if(data == 'success')
      {
        /*$('#result').html('Comment Added Sucessfully');
        $('.error').css("color", "green");
        $('#c_name').hide("");*/
        alert('Comment Added Sucessfully');
        window.location.reload(true);
      }
      else
      {
        $('#result').html('');
        $('.error').css("color", "red");
      }
    })

  }
    
    return (
      <div className='viewcandidate'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          {/*<h1>View User</h1>*/}
    </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/candidates/All">Manage Candidate</a></li>
            <li className="breadcrumb-item active">View Candidate</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        {/*<div className="col-md-2">
            
    </div>*/}
        <div className="col-md-12">
        <div className="card-body" id="candidate_table"></div>
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
            <h4>View Candidate</h4>
            </div>
            {/* /.card-header */}
            {/* form start */}
            
              <div className="card-body">
              
              <table id="usertble" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Candidate Name</th>
                    <th>Type Of Visa</th>
                    <th>Assigned Consultant</th>
                    <th>Email ID</th>
                    <th>Phonenumber</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Current Status</th>
                  </tr>
                </thead>
                <tbody>
                
             
                 <tr>
                 <td id="idval"></td>
                  <td id="name"></td>
                  <td id="visa"></td>
                  <td id="consultant"></td>
                  <td id="email"></td>
                  <td id="phone"></td>
                  <td id="state"></td>
                  <td id="city"></td>
                  <td id="status"></td>
                  
                 
                </tr>
             
            
                  
                </tbody>
                
              </table>

                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
              <a href="../../candidates/All"><button type="button" className="btn btn-primary">Back</button></a>
              <button type="button" className="btn btn-primary" onClick={addcomment} style={{float: "right"}}>Add Comment</button>
              </div>
           
          </div>
          {/* /.card */}
        </div>
        {/*/.col (left) */}
        {/* right column */}
        {/*<div className="col-md-2">
        </div>*/}
        {/*/.col (right) */}
      </div>
      {/* /.row */}
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-12">
        <h3>Earlier Comments</h3>
        
        {logOptions.map((localState, index) => (
         
          <><div className="dialogbox">
            <div className="body">
              <span className="tip tip-up" />
              <div className="message">
                <span>{localState.Current_Status}</span>
              </div>
            </div>
          </div></>
            ))}
        </div>
        <div className="col-md-2"></div>
        
                  
      </div>
      <div className="row" id='addcomment'>
        <div className='col-md-2'>

        </div>
        <div className='col-md-8'>
        <form id="add_comment" onSubmit={submitHandler}>
                <div className="card-body">
                    <div className="form-group">
                      
                      <label htmlFor="exampleInputEmail1">Comment</label><sup className='validation'>*</sup>
                      <input type="text" name="c_name" className="form-control" id="c_name" placeholder="Enter Comment" required  />
                      <input type="hidden" name='candidate_id' id='candidate_id'/>
                      <input type="hidden" name='visa_type' id='visa_type'/>
                      <input type="hidden" name='consultant_id' id='consultant_id'/>
                      <input type="hidden" name='status_comment' id='status_comment'/>
                      
                    </div>
                </div>
                <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
          </form>
        </div>
        <div className='col-md-2'>

        </div>
          
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>


       
    )
}
