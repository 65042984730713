import React, { Component } from 'react'
import Sidebar from './components/sidebar/Sidebar'
import Header from './components/header/Header'
import axios from 'axios';
import $ from 'jquery';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery-3.6.0.js';
import 'jquery/dist/jquery-ui.js';
import 'jquery/dist/jquery-ui.css';
//Datatable Modules

import DataTable from 'datatables.net';
import './index.css';
import ReactSlider from "react-slider";
let result;
let result_case;
let newdata;
let newdata_val;
let newdata_active;
let newdata_bar;
let obj;
let conactive;
let visatype;
let consultant;
let activeconsultant;
let status;
let year;
let pstatus;
let rangeval;
let pnprangeval;
let pnpval;
let count =1;
let title;
let subtitle_val;
export default class Dashboard extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    const year = (new Date()).getFullYear();
    this.years = Array.from(new Array(4),(val, index) => year - index);
    this.state = {
     
          TeamData: [],
          chartvalue: [],
          data: [],
          serachdata: []
    };
    
    
    
    
}
  async componentDidMount() 
    {
      $( "#sliderpnprange" ).slider({
        range: true,
        min: 0,
        max: 120,
        values: [ 0, 120 ],
        slide: async function( event, ui ) {
          $( "#pnplabel" ).html( "PNP range : "+ ui.values[ 0 ] + " - " + ui.values[ 1 ] );
          $( "#pnprangeval" ).val(ui.values[ 0 ] + "-" + ui.values[ 1 ]);
        },
        stop: async function( event, ui ) {
         
          visatype=$('#visatype').val();
          consultant=$('#consultant').val();
          
          pnprangeval = $( "#pnprangeval" ).val();
          status=$('#status').val();
          year=$('#year').val();
          pstatus=$('#pstatus').val();
          //alert(status);
          //alert(consultant);
          //alert(consultant);
         var formData = new FormData();
         formData.append('visatype', visatype);
         formData.append('consultant', consultant);
         formData.append('status', status);
         formData.append('year', year);
         formData.append('pstatus', pstatus);
         formData.append('pnprangeval', pnprangeval);
         //alert(visatype);
         await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisatype_react.php`,{
           method:'POST',
           headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
           }),
           body: formData,
           }) .then((response) => 
             response.json() 
       )
             .then((data) => {
             //console.log(data);
             
             if(status =='')
          {
           status='All';
          }
          if(consultant =='')
          {
           consultant='All';
          }
          if(visatype =='')
          {
           visatype='All';
          }
           // var obj = $.parseJSON(data);
           $("#moreinfopr").html(`<a href='candidates/PR/${consultant}/${status}' className="small-box-footer info">More info <i className="fas fa-arrow-circle-right"/></a>`);
           $("#moreinfowm").html(`<a href='candidates/Work Permit/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfolmia").html(`<a href='candidates/LMIAs/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfomisc").html(`<a href='candidates/Misc/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfopnp").html(`<a href='candidates/PNP/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfodependent").html(`<a href='candidates/Dependent/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfostudent").html(`<a href='candidates/Student/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfosuper").html(`<a href='candidates/Super/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfovisitor").html(`<a href='candidates/Visitor/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfototalcases").html(`<a href='candidates/${visatype}/${consultant}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfoinprogresscases").html(`<a href='candidates/inprogress/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfoinprogresstcases").html(`<a href='candidates/inprogresst/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfofailcases").html(`<a href='candidates/fail/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           $("#moreinfosuccesscases").html(`<a href='candidates/success/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
           if(year =='All' && visatype!='All'  && consultant!='Allactive')
           {
             title="Year";	
             subtitle_val="Yearly Performance Report";			
             newdata_val = $.map(data.year, function(el, index) {
               return {
                   name: el,
                   y: parseInt(data['candidatescount'][index])
                   };
                   });
           }
           else if(year !='All' && visatype!='All')
           {
             
             title="Year";		
             subtitle_val="Yearly Performance Report";			
             newdata_val = $.map(data.month, function(el, index) {
                 return {
                       name: el,
                       y: parseInt(data['successcount'][index])
                     };
               });
           }
           else if(year !='All' && visatype=='All')
            {
             title="All Visa's";
             subtitle_val="All Visa's Performance Report";
             newdata_val = $.map(data.year, function(el, index) {
               return {
                   name: el,
                   y: parseInt(data['candidatescount'][index])
                   };
                   });
            }
            else if(year =='All' && visatype=='All' && consultant!='Allactive')
            {
             title="All Visa's";
             subtitle_val="All Visa's Performance Report";
             newdata_val = $.map(data.year, function(el, index) {
               return {
                   name: el,
                   y: parseInt(data['candidatescount'][index])
                   };
                   });
            }
            else if(year =='All' && visatype=='All' && consultant == 'Allactive')
            {
              obj =data;
            }
            else if(consultant == 'Allactive')
            {
             newdata_val = $.map(data.year, function(el, index) {
               return {
                   name: el,
                   y: parseInt(data['candidatescount'][index])
                   };
                   });
            }
           })
   //this.highchartsnew();
   if(consultant == 'Allactive' && visatype=='All')
   {
     //alert(data);
      //conactive= JSON.parse(activeconsultant); 
     Highcharts.chart({
       chart: {
         height: 500,
         type: 'column',
         renderTo: 'visa-composition'
       },
     title: {
         text: 'Consultant Performance Report',
         
     },
   
     subtitle: {
         text: subtitle_val
     },
     xAxis: {
                 labels: {
                   style: {
                       fontSize: '16px',
                      
                   }
               },
                 title: {
                   text: title
                     },
                     categories : activeconsultant,
                     crosshair: true,
                     },
     yAxis: {
         title: {
             text: 'Number of Candidates'
         }
     },
   
     plotOptions: {
       column: {
         minPointLength: 20
       },
       series: {
         dataLabels: {
                   enabled: true,
                   style: {
                     
                     fontSize:14,
                 },
               }
           
           
       }
         
     },
     series: obj,
   
     responsive: {
         rules: [{
             condition: {
                 maxWidth: 500
             },
             chartOptions: {
                 legend: {
                     layout: 'horizontal',
                     align: 'center',
                     verticalAlign: 'bottom'
                 }
             }
         }]
       }
       });
   }
   else
   {
     Highcharts.chart({
       chart: {
         height: 500,
         type: 'column',
         renderTo: 'visa-composition'
       },
     title: {
         text: 'Company Performance Report',
         
     },
   
     subtitle: {
         text: subtitle_val
     },
     xAxis: {
                 labels: {
                   style: {
                       fontSize: '16px',
                      
                   }
               },
                 title: {
                   text: title
                     },
                 type: 'category',
                     },
     yAxis: {
         title: {
             text: 'Number of Candidates'
         }
     },
   
     plotOptions: {
       column: {
         minPointLength: 3
       },
         series: {
           dataLabels: {
                     enabled: true,
                     style: {
                       
                       fontSize:14,
                   },
                 },
             label: {
                 connectorAllowed: false,
                 
             },
             pointWidth: 35,
             color: '#22ca0f',
             
             
         }
     },
     series: [{
         name: visatype,
         data:newdata_val          
     }],
   
     responsive: {
         rules: [{
             condition: {
                 maxWidth: 500
             },
             chartOptions: {
                 legend: {
                     layout: 'horizontal',
                     align: 'center',
                     verticalAlign: 'bottom'
                 }
             }
         }]
       }
       });
   }
               
   await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisatype_tabledata_react.php`,{
                   method:'POST',
                   headers: new Headers({
                   // 'Content-Type': 'application/x-www-form-urlencoded',
                   }),
                   body: formData,
                   }).then((response) => response.json())
                   .then((data) => {
                   console.log(data);
                     if(data == 'No Data Avaliable')
                       {
                             $("#searchdata_table").hide();
                       }
                       else{
                        // $("#searchdata_table").show();
                        $('#searchdata_table').html('');
                         $('#searchdata_table').html(data);
                         $("#pr_usertable_data").DataTable({
                          "pageLength": 100,
                          dom: 'Bfrtip',
                          buttons: [
                              'csv', 'excel', 'pdf', 'print'
                          ]
                        });
                           
                         //this.setState({serachdata: data});
                       }
                })
          
     }
      });
      $( "#slider-range" ).slider({
        range: true,
        min: 0,
        max: 1200,
        values: [ 0, 1200 ],
        slide: async function( event, ui ) {
          $( "#amount" ).html( "CRS range : "+ ui.values[ 0 ] + " - " + ui.values[ 1 ] );
          $( "#rangeval" ).val(ui.values[ 0 ] + "-" + ui.values[ 1 ]);
        },
        stop: async function( event, ui ) {
         
          
          
             visatype=$('#visatype').val();
             consultant=$('#consultant').val();
             
             rangeval = $( "#rangeval" ).val();
             status=$('#status').val();
             year=$('#year').val();
             pstatus=$('#pstatus').val();
             //alert(status);
             //alert(consultant);
             //alert(consultant);
            var formData = new FormData();
            formData.append('visatype', visatype);
            formData.append('consultant', consultant);
            formData.append('status', status);
            formData.append('year', year);
            formData.append('pstatus', pstatus);
            formData.append('rangeval', rangeval);
            //alert(visatype);
            await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisatype_react.php`,{
              method:'POST',
              headers: new Headers({
              // 'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: formData,
              }) .then((response) => 
                response.json() 
          )
                .then((data) => {
                //console.log(data);
                
                if(status =='')
             {
              status='All';
             }
             if(consultant =='')
             {
              consultant='All';
             }
             if(visatype =='')
             {
              visatype='All';
             }
              // var obj = $.parseJSON(data);
              $("#moreinfopr").html(`<a href='candidates/PR/${consultant}/${status}' className="small-box-footer info">More info <i className="fas fa-arrow-circle-right"/></a>`);
              $("#moreinfowm").html(`<a href='candidates/Work Permit/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfomisc").html(`<a href='candidates/Misc/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfopnp").html(`<a href='candidates/PNP/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfodependent").html(`<a href='candidates/Dependent/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfostudent").html(`<a href='candidates/Student/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfosuper").html(`<a href='candidates/Super/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfovisitor").html(`<a href='candidates/Visitor/${consultant}/${status}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfototalcases").html(`<a href='candidates/${visatype}/${consultant}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfoinprogresscases").html(`<a href='candidates/inprogress/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfoinprogresstcases").html(`<a href='candidates/inprogresst/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfofailcases").html(`<a href='candidates/fail/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              $("#moreinfosuccesscases").html(`<a href='candidates/success/${consultant}/${visatype}' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
              if(year =='All' && visatype!='All'  && consultant!='Allactive')
              {
                title="Year";	
                subtitle_val="Yearly Performance Report";			
                newdata_val = $.map(data.year, function(el, index) {
                  return {
                      name: el,
                      y: parseInt(data['candidatescount'][index])
                      };
                      });
              }
              else if(year !='All' && visatype!='All')
              {
                
                title="Year";		
                subtitle_val="Yearly Performance Report";			
                newdata_val = $.map(data.month, function(el, index) {
                    return {
                          name: el,
                          y: parseInt(data['successcount'][index])
                        };
                  });
              }
              else if(year !='All' && visatype=='All')
               {
                title="All Visa's";
                subtitle_val="All Visa's Performance Report";
                newdata_val = $.map(data.year, function(el, index) {
                  return {
                      name: el,
                      y: parseInt(data['candidatescount'][index])
                      };
                      });
               }
               else if(year =='All' && visatype=='All' && consultant!='Allactive')
               {
                title="All Visa's";
                subtitle_val="All Visa's Performance Report";
                newdata_val = $.map(data.year, function(el, index) {
                  return {
                      name: el,
                      y: parseInt(data['candidatescount'][index])
                      };
                      });
               }
               else if(year =='All' && visatype=='All' && consultant == 'Allactive')
               {
                 obj =data;
               }
               else if(consultant == 'Allactive')
               {
                newdata_val = $.map(data.year, function(el, index) {
                  return {
                      name: el,
                      y: parseInt(data['candidatescount'][index])
                      };
                      });
               }
              })
      //this.highchartsnew();
      if(consultant == 'Allactive' && visatype=='All')
      {
        //alert(data);
         //conactive= JSON.parse(activeconsultant); 
        Highcharts.chart({
          chart: {
            height: 500,
            type: 'column',
            renderTo: 'visa-composition'
          },
        title: {
            text: 'Consultant Performance Report',
            
        },
      
        subtitle: {
            text: subtitle_val
        },
        xAxis: {
                    labels: {
                      style: {
                          fontSize: '16px',
                         
                      }
                  },
                    title: {
                      text: title
                        },
                        categories : activeconsultant,
                        crosshair: true,
                        },
        yAxis: {
            title: {
                text: 'Number of Candidates'
            }
        },
      
        plotOptions: {
          column: {
            minPointLength: 20
          },
          series: {
            dataLabels: {
                      enabled: true,
                      style: {
                        
                        fontSize:14,
                    },
                  }
              
              
          }
            
        },
        series: obj,
      
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
          }
          });
      }
      else
      {
        Highcharts.chart({
          chart: {
            height: 500,
            type: 'column',
            renderTo: 'visa-composition'
          },
        title: {
            text: 'Company Performance Report',
            
        },
      
        subtitle: {
            text: subtitle_val
        },
        xAxis: {
                    labels: {
                      style: {
                          fontSize: '16px',
                         
                      }
                  },
                    title: {
                      text: title
                        },
                    type: 'category',
                        },
        yAxis: {
            title: {
                text: 'Number of Candidates'
            }
        },
      
        plotOptions: {
          column: {
            minPointLength: 3
          },
            series: {
              dataLabels: {
                        enabled: true,
                        style: {
                          
                          fontSize:14,
                      },
                    },
                label: {
                    connectorAllowed: false,
                    
                },
                pointWidth: 35,
                color: '#22ca0f',
                
                
            }
        },
        series: [{
            name: visatype,
            data:newdata_val          
        }],
      
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
          }
          });
      }
                  
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisatype_tabledata_react.php`,{
                      method:'POST',
                      headers: new Headers({
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                      }),
                      body: formData,
                      }).then((response) => response.json())
                      .then((data) => {
                      console.log(data);
                        if(data == 'No Data Avaliable')
                          {
                                $("#searchdata_table").hide();
                          }
                          else{
                           // $("#searchdata_table").show();
                           $('#searchdata_table').html('');
                            $('#searchdata_table').html(data);
                            
                            //this.setState({serachdata: data});
                          }
                   })
                      
      
                   
                          
                        
      
            
          

      
        }
      });
      
      
     
      $(".dashboard").addClass("active");
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/dashboard_react.php`).then(res => 
        {
        //this.setState({data: res.data});
         result = res.data.split('&');
         $('#pr').html(result[0]);
         $('#visitor').html(result[1]);
         $('#student').html(result[2]);
         $('#work').html(result[6]);
         $('#dependant').html(result[4]);
         $('#pnp').html(result[5]);
         $('#wp').html(result[3]);
         $('#misc').html(result[7]);
         $('#total').html(result[8]);
         $('#active').html(result[9]);
         $('#running').html(result[11]);
         $('#fail').html(result[10]);
         $('#startup').html(result[12]);
         $('#spousalpr').html(result[13]);
         $('#lmia').html(result[14]);
           });
           await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getprvisa_react.php`,{
            method:'POST',
            headers: new Headers({
               // 'Content-Type': 'application/x-www-form-urlencoded',
       }),
       body: 'PR',
        }) .then((response) => response.json())
        .then((data) => {
          //console.log(data);
         // var obj = $.parseJSON(data);
		    newdata = $.map(data.year, function(el, index) {
		    return {
				name: el,
				y: parseInt(data['candidatescount'][index])
				};
				});
        this.highChartsRender();
        })
        
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprvisateabledata_react.php`).then(res => 
          {
          //this.setState({serachdata: res.data});
          $('#searchdata_table').html(res.data);
          $("#pr_usertable_data").DataTable({
            "pageLength": 100,
            dom: 'Bfrtip',
		        buttons: [
		            'csv', 'excel', 'pdf', 'print'
		        ]
          });
             });
           
             //get active consultants
             axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getactiveconsultants_react.php`).then(res => 
              {
              //this.setState({serachdata: res.data});
              //alert(res.data);
              activeconsultant =res.data;
                 });
             
        
           axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteam_react.php`).then(response => {
            
            this.setState({
            TeamData: response.data
            });
            });
            axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getsuccessrate_react.php`).then(response => {
            
             // alert(response.data);
             const  succ= response.data+'%'
              $("#successval").html(succ);
              });
            /*axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprvisa_react.php`).then(response => {
              console.log(response.data);
              
              
              });*/
            
                 
           
    }
    highChartsRender() {
      Highcharts.chart({
        chart: {
          height: 500,
          type: 'column',
          renderTo: 'visa-composition'
         },
       title: {
           text: 'Company Performance Report'
       },

       subtitle: {
           text: "All Visa's Performance Report"
       },
     xAxis: {
                  labels: {
                    style: {
                        fontSize: '16px',
                      
                    }
                },
                  type: 'category',
                       },
       yAxis: {
           title: {
               text: 'Number of Candidates'
           }
       },

       plotOptions: {
         column: {
           minPointLength: 3
         },
           series: {
             dataLabels: {
                       enabled: true,
                       style: {
                              
                        fontSize:14,
                    },
                   },
               label: {
                   connectorAllowed: false
               },
               pointWidth: 35,
               color: '#22ca0f',
               
               
           }
       },
       series: [{
           name: "All Visa's",
           data:newdata          
       }],

       responsive: {
           rules: [{
               condition: {
                   maxWidth: 500
               },
               chartOptions: {
                   legend: {
                       layout: 'horizontal',
                       align: 'center',
                       verticalAlign: 'bottom'
                   }
               }
           }]
       }
        });
    }
    getdata = async(event) => {
      event.preventDefault();
      this.setState({selectValue: event.target.value});
       visatype=$('#visatype').val();
       consultant=$('#consultant').val();
       status=$('#status').val();
       year=$('#year').val();
       pstatus=$('#pstatus').val();
       var formData = new FormData();
      formData.append('visatype', visatype);
      formData.append('consultant', consultant);
      formData.append('status', status);
      formData.append('year', year);
      formData.append('pstatus', pstatus);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getcandiadates_moreinfodata.php`,{
        method:'POST',
        headers: new Headers({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: formData,
        }) .then((response) => response.json())
        .then((data) => {
        console.log(data);
      // var obj = $.parseJSON(data);
      
      })
    }
     visaData = async(event) => {
      event.preventDefault();
      this.setState({selectValue: event.target.value});
       visatype=$('#visatype').val();
       consultant=$('#consultant').val();
       
       rangeval = $( "#rangeval" ).val();
       status=$('#status').val();
       year=$('#year').val();
       pstatus=$('#pstatus').val();
       //alert(rangeval);
       //alert(consultant);
       //alert(consultant);
      var formData = new FormData();
      formData.append('visatype', visatype);
      formData.append('consultant', consultant);
      formData.append('status', status);
      formData.append('year', year);
      formData.append('pstatus', pstatus);
      formData.append('rangeval', rangeval);
      //alert(visatype);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisatype_react.php`,{
        method:'POST',
        headers: new Headers({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: formData,
        }) .then((response) => 
          response.json() 
    )
          .then((data) => {
          //console.log(data);
          
          if(status =='')
       {
        status='All';
       }
       if(consultant =='')
       {
        consultant='All';
       }
       if(visatype =='')
       {
        visatype='All';
       }
        // var obj = $.parseJSON(data);
        $("#moreinfopr").html(`<a href='candidates/PR/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className="fas fa-arrow-circle-right"/></a>`);
        $("#moreinfowm").html(`<a href='candidates/Work Permit/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfolmia").html(`<a href='candidates/LMIAs/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfomisc").html(`<a href='candidates/Misc/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfopnp").html(`<a href='candidates/PNP/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfodependent").html(`<a href='candidates/Dependent/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfostudent").html(`<a href='candidates/Student/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfostartup").html(`<a href='candidates/Startup/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfospousalpr").html(`<a href='candidates/Spousal PR/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfosuper").html(`<a href='candidates/Super/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfovisitor").html(`<a href='candidates/Visitor/${consultant}/${status}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfototalcases").html(`<a href='candidates/${visatype}/${consultant}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfoinprogresscases").html(`<a href='candidates/inprogress/${consultant}/${visatype}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfoinprogresstcases").html(`<a href='candidates/inprogresst/${consultant}/${visatype}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfofailcases").html(`<a href='candidates/fail/${consultant}/${visatype}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        $("#moreinfosuccesscases").html(`<a href='candidates/success/${consultant}/${visatype}' target='_blank' className="small-box-footer info">More info <i className='fas fa-arrow-circle-right' /></a>`);
        if(year =='All' && visatype!='All'  && consultant!='Allactive')
        {
          title="Year";	
          subtitle_val="Yearly Performance Report";			
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
        }
        else if(year !='All' && visatype!='All')
        {
          
          title="Year";		
          subtitle_val="Yearly Performance Report";			
          newdata_val = $.map(data.month, function(el, index) {
							return {
										name: el,
										y: parseInt(data['successcount'][index])
									};
						});
        }
        else if(year !='All' && visatype=='All')
         {
          title="All Visa's";
          subtitle_val="All Visa's Performance Report";
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
         else if(year =='All' && visatype=='All' && consultant!='Allactive')
         {
          title="All Visa's";
          subtitle_val="All Visa's Performance Report";
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
         else if(year =='All' && visatype=='All' && consultant == 'Allactive')
         {
           obj =data;
         }
         else if(consultant == 'Allactive')
         {
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
        })
//this.highchartsnew();
if(consultant == 'Allactive' && visatype=='All')
{
  //alert(data);
   //conactive= JSON.parse(activeconsultant); 
  Highcharts.chart({
    chart: {
      height: 500,
      type: 'column',
      renderTo: 'visa-composition'
    },
  title: {
      text: 'Consultant Performance Report',
      
  },

  subtitle: {
      text: subtitle_val
  },
  xAxis: {
              labels: {
                style: {
                    fontSize: '16px',
                   
                }
            },
              title: {
                text: title
                  },
                  categories : activeconsultant,
                  crosshair: true,
                  },
  yAxis: {
      title: {
          text: 'Number of Candidates'
      }
  },

  plotOptions: {
    column: {
      minPointLength: 20
    },
    series: {
      dataLabels: {
                enabled: true,
                style: {
                  
                  fontSize:14,
              },
            }
        
        
    }
      
  },
  series: obj,

  responsive: {
      rules: [{
          condition: {
              maxWidth: 500
          },
          chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
              }
          }
      }]
    }
    });
}
else
{
  Highcharts.chart({
    chart: {
      height: 500,
      type: 'column',
      renderTo: 'visa-composition'
    },
  title: {
      text: 'Company Performance Report',
      
  },

  subtitle: {
      text: subtitle_val
  },
  xAxis: {
              labels: {
                style: {
                    fontSize: '16px',
                   
                }
            },
              title: {
                text: title
                  },
              type: 'category',
                  },
  yAxis: {
      title: {
          text: 'Number of Candidates'
      }
  },

  plotOptions: {
    column: {
      minPointLength: 3
    },
      series: {
        dataLabels: {
                  enabled: true,
                  style: {
                    
                    fontSize:14,
                },
              },
          label: {
              connectorAllowed: false,
              
          },
          pointWidth: 35,
          color: '#22ca0f',
          
          
      }
  },
  series: [{
      name: visatype,
      data:newdata_val          
  }],

  responsive: {
      rules: [{
          condition: {
              maxWidth: 500
          },
          chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
              }
          }
      }]
    }
    });
}
            
              await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisatype_tabledata_react.php`,{
                method:'POST',
                headers: new Headers({
                // 'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: formData,
                }).then((response) => response.json())
                .then((data) => {
                console.log(data);
                  if(data == 'No Data Avaliable')
                    {
                          $("#searchdata_table").hide();
                    }
                    else{
                     // $("#searchdata_table").show();
                     $('#searchdata_table').html('');
                      $('#searchdata_table').html(data);
                      $("#pr_usertable_data").DataTable({
                        "pageLength": 100,
                        dom: 'Bfrtip',
                        buttons: [
                            'csv', 'excel', 'pdf', 'print'
                        ]
                      });
                      
                      //this.setState({serachdata: data});
                    }
             })
                

                await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getsuccessrate_searchdata_react.php`,{
                  method:'POST',
                  headers: new Headers({
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }),
                  body: formData,
                  }) .then((response) => response.json())
                    .then((data) => {
                      console.log(data);
                      const  succ= data+'%'
                      if(status == 5) 
                      {
                          $("#rate").html('Failurerate');
                          $("#successval").html(succ);
                      }
                      else
                      {
                        $("#rate").html('Successrate');
                        $("#successval").html(succ);
                      }
                     
                     
                  })

                  await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getcases_searchdata_react.php`,{
                    method:'POST',
                    headers: new Headers({
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: formData,
                    }) .then((response) => response.json())
                      .then((data) => {
                        console.log(data);
                        //alert(data);
                        result_case = data.split('&');
                        $('#total').html(result_case[0]);
                        $('#active').html(result_case[1]);
                        $('#fail').html(result_case[2]);
                        $('#running').html(result_case[3]);
                       
                       
                    })
                    if(consultant !='' || consultant =='' || year =='All' || year !='')
                    {
                      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getvisa_searchdata_react.php`,{
                        method:'POST',
                        headers: new Headers({
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        }),
                        body: formData,
                        }) .then((response) => response.json())
                          .then((data) => {
                            console.log(data);
                            //alert(data);
                            result_case = data.split('&');
                            
         $('#pr').html(result_case[0]);
         $('#visitor').html(result_case[1]);
         $('#student').html(result_case[2]);
         $('#work').html(result_case[6]);
         $('#dependant').html(result_case[4]);
         $('#pnp').html(result_case[5]);
         $('#wp').html(result_case[3]);
         $('#misc').html(result_case[7]);
         $('#startup').html(result_case[8]);
         $('#spousalpr').html(result_case[9]);
         $('#lmia').html(result_case[10]);
        /* $('#total').html(result_case[8]);
         $('#active').html(result_case[9]);
         $('#fail').html(result_case[10]);
                            /*$('#total').html(result_case[0]);
                            $('#active').html(result_case[1]);
                            $('#fail').html(result_case[2]);*/
                           
                           
                        })
                    }
                    
                  

      } 
     
      
       
      
  render() {
    
    return (
      <div>
        <Header />
        <Sidebar />
  <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Dashboard</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h5 id='pr'></h5>
                <h5 id="h5_style">Express Entry PRs</h5>
              </div>
              <div className="icon">
                <i className="ion ion-bag" />
              </div>
              <div id="moreinfopr" className="small-box-footer info">
              <Link to="/candidates/PR/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-orange">
              <div className="inner">
               <span> <h5 id='wp'></h5></span>
                <h5 id="h5_style"> Work Permits</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfowm" className="small-box-footer info">
              <Link to="/candidates/Work Permit/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              
              </div>
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-lightblue">
              <div className="inner">
              <h5 id='misc'></h5>
                <h5 id="h5_style">Misc Services</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfomisc" className="small-box-footer info">
              <Link to="/candidates/Misc/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
             
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-pink">
              <div className="inner">
              <h5 id='pnp'></h5>
                <h5 id="h5_style">PNPs</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfopnp" className="small-box-footer info">
              <Link to="/candidates/PNP/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
          
          
          </div>
          <div className='row'>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-purple">
              <div className="inner">
                <h5 id='dependant'></h5>
                <h5 id="h5_style">SOWP / Dependent</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfodependent" className="small-box-footer info">
              <Link to="/candidates/Dependent/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h5 id='student'></h5>
                <h5 id="h5_style">Student Visa</h5>
              </div>
              <div className="icon">
                <i className="ion ion-person-add" />
              </div>
              <div id="moreinfostudent" className="small-box-footer info">
              <Link to="/candidates/Student/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-danger">
              <div className="inner">
                <h5 id='work'></h5>
                <h5 id="h5_style">Super Visa</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfosuper" className="small-box-footer info">
              <Link to="/candidates/Super/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h5 id='visitor'></h5>
                <h5 id="h5_style">Visitor Visa</h5>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars" />
              </div>
              <div id="moreinfovisitor" className="small-box-footer info">
              <Link to="/candidates/Visitor/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
        </div>
        <div className='row'>
        <div className="col-lg-3 col-6">
            <div className="small-box bg-green">
              <div className="inner">
                <h5 id='startup'></h5>
                <h5 id="h5_style">Start Up</h5>
              </div>
              <div className="icon">
                <i className="ion ion-person-add" />
              </div>
              <div id="moreinfostartup" className="small-box-footer info">
              <Link to="/candidates/Startup/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-blue">
              <div className="inner">
                <h5 id='spousalpr'></h5>
                <h5 id="h5_style">Spousal PR</h5>
              </div>
              <div className="icon">
                <i className="ion ion-person-add" />
              </div>
              <div id="moreinfospousalpr" className="small-box-footer info">
              <Link to="/candidates/Spousal PR/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-orange">
              <div className="inner">
               <span> <h5 id='lmia'></h5></span>
                <h5 id="h5_style">LMIAs</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfolmia" className="small-box-footer info">
              <Link to="/candidates/LMIAs/All/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              
              </div>
              
            </div>
          </div>
          
          <div className="col-lg-3 col-6">
            <div className="small-box bg-fuchsia">
              <div className="inner">
                
                <span> <h5 id='total'></h5></span>
                <h5 id="h5_style">Total Cases</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfototalcases" className="small-box-footer info">
              <Link to="/candidates/All" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
              
            </div>
          </div>
        </div>
        <div className='row'>
        <div className="col-lg-3 col-6">
            <div className="small-box bg-cyan">
              <div className="inner">
                <span style={{ display: 'inline-block', fontSize: 20 }}><h3 id='active'></h3>Active Cases&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                
                
                
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfoinprogresscases" className="small-box-footer info">
              <Link to="/candidates/inprogress" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
          </div>
          
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                
                
                <span style={{ display: 'inline-block',fontSize: 20 }}><h3 id='running'></h3>Running Cases</span>
                
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfoinprogresstcases" className="small-box-footer info">
              <Link to="/candidates/inprogresst" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-gray">
              <div className="inner">
                <h3 id='fail'></h3>
                <h5 id="h5_style">Failure Cases</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfofailcases" className="small-box-footer info">
              <Link to="/candidates/fail" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            
            <div className="small-box bg-info">
              <div className="inner" id="successrate">
                
                <h5 id='rate'>Successrate</h5>
                <p id='successval'></p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <div id="moreinfosuccesscases" className="small-box-footer info">
              <Link to="/candidates/success" target="_blank" className="small-box-footer info">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
             
            </div>
          
            </div>
        </div>
        
        <div className='row'>
          <section className="col-lg-12 connectedSortable">
            <div className="card">
                  <div className="card-header">
                    <div className='row'>
                        <div className="col-lg-3">
                          <label>Visa Type</label>
                        <select className='form-control' name='visatype' id='visatype' value={this.state.value} onChange={this.visaData}>
                        <option value="All" selected>All Visas </option>
                          <option value="PR" >Permanent Residency </option>
                          <option value="LMIAs">LMIA </option>
                          <option value="Misc">Misc Services</option>
                          <option value="PNP">PNPs/Job Offers/RNIPs </option>
                          <option value="Dependent">SOWP</option>
                          <option value="Student">Student Visa </option>
                          <option value="Super">Super Visa </option>
                          <option value="Visitor">Visitor Visa </option>
                          <option value="Work Permit">Work Permit Visa </option>
                          <option value="Startup">Start-up Visa</option>
                          <option value="Spousal PR">Spousal PR</option>
                        </select>
                        </div>
                        <div className="col-lg-2">
                          <label>Consultant</label>
                        <select className='form-control' name="consultant" id="consultant" value={this.state.value} onChange={this.visaData}>
                        <option value="">Select Consultant</option>
                        <option value="Allactive">All Active Consultant</option>
                                    {this.state.TeamData.map((e, key) => {  
                          return <option key={key} value={e.Teamid}>{e.Name}</option>;  
                          })}      
                        </select>
                        </div>
                        <div className="col-lg-2">
                          <label>File Status</label>
                        <select className='form-control' name="status" id="status" value={this.state.value} onChange={this.visaData}>
                        <option value="">Select File Status</option>
                        <option value='2'>Approved & Closed</option>  
                        <option value='6'>Awaiting Decision</option>  
                        <option value='3'>Customer Quit</option> 
                        <option value='1'>Inprogress</option>  
                        <option value='4'>On Hold</option>  
                        <option value='5'>Rejected</option>  
                        
                        </select>
                        </div>
                        <div className="col-lg-2">
                          <label>Year</label>
                          <select className='form-control' name="year" id="year" value={this.state.value} onChange={this.visaData}>
                          <option value="All">All Years</option>
                          {
                            this.years.map((year, index) => {
                              return <option key={`year${index}`} value={year}>{year}</option>
                            })
                          }
                          </select>
                        
                        </div>
                        <div className="col-lg-2">
                          <label>Profile Status</label>
                          <select className='form-control' name="pstatus" id="pstatus" value={this.state.value} onChange={this.visaData}>
                          <option value="">Select Profile Status</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          </select>
                        
                        </div>
                        <div className="col-lg-1">
                        <label>&nbsp;</label>
                        <button type="button" className="btn btn-primary" onClick={this.visaData}>Reload</button>
                        
                        </div>
                    </div>
                    <br></br>
                    
                  </div>
                  
                  
                  <div className="card-body">
                  <div className='row'>
                    <div className='col-lg-4'>
                      <p>
                        <label for="amount" id="amount">CRS range : 0 - 1200</label>
                        <br></br>
                        
                      </p>
                      <div id="slider-range" style={{width :'50%'}}>
                        <input type="hidden" name="rangeval" id='rangeval' />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <p>
                        <label for="pnplabel" id="pnplabel">PNP range : 0 - 120</label>
                        <br></br>
                        
                      </p>
                      <div id="sliderpnprange" style={{width :'50%'}}>
                        <input type="hidden" name="pnprangeval" id='pnprangeval' />
                      </div>
                    </div>
                  </div>
                    <hr></hr>
                  <div id="visa-composition">
  	              </div>
                  <div id='searchdata_table'>
                    </div>
                    <div>
                  
                  </div>

                  </div>
                  
            </div>
          </section>
        </div>
        
      </div>
    </section>
  </div>
</div>

    )
  }
}
