import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import './team.scss';
export default function ListUser() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const {id} = useParams();

    useEffect(() => {
        getUser();
    }, []);

    function getUser() {
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteamid_react.php?id=${id}`).then(function(response) {
            console.log(response.data[0]['Name']);
            setInputs(response.data);
             $('#name').val(response.data[0]['Name']);
             $('#desg').val(response.data[0]['Desgination']);
             $('#teamid').val(response.data[0]['Teamid']);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    const submitHandler = async (event) => {
        event.preventDefault();
    //console.log(this.state.linkname);
   // console.log(this.state.description);
    const name = $('#name').val();
    const desg = $('#desg').val();
    const idval = $('#teamid').val();
    console.log(idval);
    var formData = new FormData();
      formData.append('name', name);
      formData.append('desg', desg);
      formData.append('id', idval);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/updateteam_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }).then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('Member Details Updated Sucessfully');
        $('.error').css("color", "green");
        //$('#linkname').val("");
        //$('#description').val("");
      }
      else
      {
        $('#result').html('Please check the Name and Designation');
        $('.error').css("color", "red");
      }
    })
       /* axios.put(`http://localhost/immigrations_azure/admin/updatemarquee_react.php?id=${id}`, inputs).then(function(response){
            console.log(response.data);
            navigate('/');
        });*/
        
    }
    return (
      <div className='updateteam'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Update Team Member Details</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/team">Manage Team</a></li>
            <li className="breadcrumb-item active">Update Team</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">

        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={submitHandler}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name<sup className='validation'>*</sup></label>
                  <input type="text" name="name" className="form-control" id="name" value={inputs.name} placeholder="Enter Name" required onChange={handleChange}   />
                  <input type="hidden" name="teamid" id="teamid"/>
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Designation<sup className='validation'>*</sup></label>
                    <textarea className="form-control" value={inputs.desg} name="desg" id="desg" placeholder="Enter Designation" required onChange={handleChange} ></textarea>
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-2">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>


       
    )
}
