import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './team.scss';
import ReactCrop from 'react-image-crop'
export default class Addteam extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
        name:'',
        desg:'',
        // Initially, no file is selected
      selectedFile: null
    }
    
}

  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
    const name = this.state.name;
    const desg = this.state.desg;
    
      var formData = new FormData();
      formData.append('name', name);
      formData.append('desg', desg);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/insertteam_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'successful')
      {
        $('#result').html('Team Member Details Added Sucessfully');
        $('.error').css("color", "green");
        $('#name').val("");
        $('#desg').val("");
       
      }
      else
      {
        $('#result').html('Please check the name and desg');
        $('.error').css("color", "red");
      }
    })

}
  render() {
    $(function () {
        // Summernote
        //$('#summernote').summernote();
        CodeMirror.fromTextArea(document.getElementById("summernote"), {
          mode: "htmlmixed",
          theme: "monokai"
        });
      })
    return (
      <div className='addblog'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Add Team</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="team">Manage Team</a></li>
            <li className="breadcrumb-item active">Add Team</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">
        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
            { /*<div className="form-group">
                  <label htmlFor="exampleInputEmail1">Blog Pic<sup className='validation'>*</sup></label>
                  <br/>
                  <input type="file" name="pic"  id="pic"  required  onChange={this.onFileChange}   />
    </div>*/}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Consultant Name<sup className='validation'>*</sup></label>
                  <input type="text" name="name" className="form-control" id="name" placeholder="Enter Name" required onChange={(event)=>{
                        this.setState({
                            name:event.target.value
                        });
                    }} value={this.state.name}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Designation<sup className='validation'>*</sup></label>
                  <input type="text" name="desg" className="form-control" id="desg" placeholder="Enter Designation" required onChange={(event)=>{
                        this.setState({
                            desg:event.target.value
                        });
                    }} value={this.state.desg}  />
                </div>
               
                {/* <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description<sup className='validation'>*</sup></label>
                    <textarea className="form-control" name="description" id="description" required onChange={(event)=>{
                        this.setState({
                            description:event.target.value
                        });
                    }} value={this.state.description}></textarea>
                </div>*/}
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        <div className="col-md-2">
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}
