import { Link } from "react-router-dom";
import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './candidate.scss';
import ReactCrop from 'react-image-crop'
import axios from 'axios';
import withRouter from '../../withRouter';

class Candidates extends Component {
  static propTypes = {
    prop: PropTypes
  }
    constructor(props) {
        super(props);
        this.state = {
          data: []
        };
      }
      async componentDidMount() 
    {
      $(".candidate").addClass("active");
      let visavalue = this.props.params.visa;
      let consultant = this.props.params.consultant;
      let status = this.props.params.status;
      //alert(consultant);
      if(typeof consultant === 'undefined')
      {
        consultant='';
      }
      if(typeof status === 'undefined')
      {
        status='';
      }
      
      
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcandidates_react.php?visa=${visavalue}&consultant=${consultant}&status=${status}`).then(res => 
          {
          //this.setState({data: res.data});
          $('#candidate_table').html(res.data);
            $("#usertable_ajax").DataTable(
              {
                "pageLength": 100
              }
            );
            $("#usertble").hide();
             });
      
        
    }
  render() {
   // const { hits } = this.state;  
   // console.log(data)
   
    return (
     <div className='users'>
      <Header />
      <Sidebar/>
      
    <div className='content-wrapper'>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Manage Candidates</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
            <li className="breadcrumb-item active">Candidates</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
          <div className="card-header">
            <p id="result" className="error"></p>
              <h3 className="card-title"><a href='/addcandidate'><button className='btn btn-info float-right'>Add Candidate</button></a></h3>
            </div>
            <div className="card-body" id="candidate_table">
              <table id="usertble" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Candidate Name</th>
                    <th>Type Of Visa</th>
                    <th>Assigned Consultant</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.data.map((result) => {
            return (
             
                 <tr>
                  <td>{result.candidateid}</td>
                  <td>{result.Name}</td>
                  <td>{result.Typeofvisa}</td>
                  <td>{result.Consultant}</td>
                  <td>
                                  {(() => {
                        if (result.Status == 1) {
                          return (
                            <div className='badge badge-success'>Inprogress</div>
                          )
                        } else if (result.Status == 0) {
                          return (
                            <div className='badge badge-danger'>Inactive</div>
                          )
                        }else if (result.Status == 3) {
                          return (
                            <div className='badge badge-warning'>Quit</div>
                          )
                        }
                        else if (result.Status == 4) {
                          return (
                            <div className='badge badge-warning'>On Hold</div>
                          )
                        }
                        else if (result.Status == 5) {
                          return (
                            <div className='badge badge-danger'>Rejected</div>
                          )
                        }
                        else if (result.Status == 6) {
                          return (
                            <div className='badge badge-warning'>Awaiting Decision</div>
                          )
                        }
                         else {
                          return (
                            <div className='badge badge-warning'>Completed</div>
                          )
                        }
                      })()}
                  </td>
                  <td>
                  <Link to={`${result.candidateid}/View`}><button type="button" className="btn btn-success vminwidth">View</button></Link>
                  <br/><br/>
                  <Link to={`${result.candidateid}/edit`}><button type="button" className="btn btn-primary vminwidth">Update</button></Link>
                  </td>
                </tr>
            )
          })}
                  
                </tbody>
                <tfoot>
                  <tr>
                  <th>Sno</th>
                    <th>Candidate Name</th>
                    <th>Type Of Visa</th>
                    <th>Assigned Consultant</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            {/* /.card-body */}
          </div>
        
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
</div>

   
    </div>

    )
  }
}export default withRouter(Candidates);
