import React, { Component } from 'react'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';
import './course.scss';

export default class Course extends Component {
    constructor(props) {
      
        super(props);
        this.state = {
          searchtext:'',
          searchStatus:'',
          selectValue: "",
          data: [],
          serachdata: [],
          loading: 'false',
        };
      }
      async componentDidMount() 
    {
      $(".course").addClass("active");
      this.setState({
        loading: 'true'
      });
      var loading = $('.loader');
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcourses_react.php`).then(res => 
        {
        this.setState({data: res.data});
        $("#universitysearch_table").hide();
          $("#universitymain_table").show();
          $('#universitymain_table').html(res.data);
          $("#coursetable_ajax").DataTable({
            "columns": [
              null,
              { "width": "40%" },
              { "width": "20%" },
              null,
              null
              
            ],
            "pageLength": 100
           
          });
        
           }).finally(() => {
            loading.hide();
        });
    }
    
    Changestatus = async (event) => {
      event.preventDefault();
      const changeId = event.target.dataset.remove;
      const statusvalue = event.target.dataset.status;
      console.log(changeId);
      console.log(statusvalue);
        var formData = new FormData();
        formData.append('id', changeId);
        formData.append('status', statusvalue);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/Changeuniversitystatus_react.php`,{
          method:'POST',
          headers: new Headers({
             // 'Content-Type': 'application/x-www-form-urlencoded',
     }),
     body: formData,
      }) .then((response) => response.json())
      .then((data) => {
        
        if(data == 'success')
        {
          alert('Status Updated Sucessfully');
          window.location.href ='Universities';
          //$('#result').html('Status Updated Sucessfully');
         // $('.error').css("color", "green");
          
        }
        else
        {
          $('#result').html('Update failed');
          $('.error').css("color", "red");
        }
      })
      
  }
  submitHandler = async (event) => {
    event.preventDefault();
    console.log(this.state.searchtext);
    
    const searchtext = this.state.searchtext;
    const searchStatus =  this.state.searchStatus;
   
      var formData = new FormData();
      formData.append('searchtext', searchtext);
      formData.append('searchStatus', searchStatus);
      
      
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getcoursesearchdata_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }).then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
     // this.setState({serachdata: data});
      $("#universitymain_table").hide();
      $("#universitytable_wrapper").hide();
      $("#universitysearch_table").show();
      $('#universitysearch_table').html(data);
      $("#coursetablesearch_ajax").DataTable({
        "columnDefs": [
          { "width": "20%", "targets": 1 }
        ],
        "pageLength": 100
      });
    })

}
Clearfilters = (event) =>{
  document.getElementById("quickForm").reset();
}
handleChange = (event) => {
  this.setState({selectValue: event.target.value});
  } 
  render() {
   // const { hits } = this.state;  
   // console.log(data)
  
    return (
     <div className='course'>
      <Header />
      <Sidebar/>
      <div class="loader">
	
</div>
    <div className='content-wrapper'>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Manage Courses</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
            <li className="breadcrumb-item active">Courses</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
      <div className="col-12 marg_botton">
          <div className='fleft'>
          <h3 className="card-title"><a href='../addcourse'><button className='btn btn-info'>Add Course</button></a></h3>
          <br/>
          </div>
          
          <div className='fright'>
          <h3 className="card-title"><a href='../importcourse'><button className='btn btn-info'>Import Courses</button></a></h3>
          <br/>
          </div>
        </div>
        <br/>
        <div className="col-12">
          <div className="card">
          <form id="quickForm" onSubmit={this.submitHandler}>
            <div className="card-header">
            <p id="result" className="error"></p>
            <div className='row'>
              
                <div className='col-md-3 fleft'>
                
                </div>
              <div className='col-md-6'>
                <div className="form-group row">
                  <div className='col-md-3'>
                  <label htmlFor="exampleInputEmail1">Search by text</label>
                  </div>
                <div className='col-md-9'>
                    <input type="text" name="searchtext" className="form-control labelstyle" id="searchtext" placeholder="Enter Search Text"   onChange={(event)=>{
                            this.setState({
                              searchtext:event.target.value
                            });
                        }} value={this.state.searchtext}  />
                </div>
                  
                </div>
                <div className="form-group row">
                  <div className='col-md-3'>
                    <label htmlFor="exampleInputEmail1">Status<sup className='validation'>*</sup></label>
                  </div>
                  <div className='col-md-9'>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline radio_style">
                    <input type="radio" name="searchStatus" id="searchStatusActive" defaultValue="Active"  onChange={(event)=>{
                        this.setState({
                          searchStatus:event.target.value
                        });
                    }}  /> Active
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline radio_style">
                    <input type="radio" name="searchStatus"   id="searchStatusInactive" defaultValue="Inactive" onChange={(event)=>{
                        this.setState({
                          searchStatus:event.target.value
                        });
                    }}  /> Inactive
                  </label>
                  <label className="radio-inline vsmalltextpink col-md-3 nomargin vdisplay-inline radio_style">
                    <input type="radio" name="searchStatus"  id="searchStatusPending" defaultValue="Pending" onChange={(event)=>{
                        this.setState({
                          searchStatus:event.target.value
                        });
                    }}  /> Pending
                  </label>

                  </div>
               
                </div>
                
               
                
                <div className="form-group fcenter">
                <button type="submit" className="btn btn-primary">Submit</button>&nbsp;
               <a href="/courses"><button type="button" className="btn btn-primary" onClick={this.Clearfilters}>Clear filters</button></a>
                </div>
              </div>
                <div className='col-md-3'>
&nbsp;
                </div>
              
              
              </div>
              
            </div>
            </form>
            {/* /.card-header */}
            <div className="card-body">
              <div  id='universitysearch_table'>
            <table id="searchcoursetable" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Course Title</th>
                    <th>University</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.serachdata.map((result) => {
            return (
             
                 <tr>
                  <td>{result.CourseId}</td>
                  <td>{result.CourseName}</td>
                  <td>{result.CollegeName}</td>
                  <td>
                                  {(() => {
                        if (result.Status == 'Active') {
                          return (
                            <div className='badge badge-success'>Active</div>
                          )
                        } else if (result.Status == 'Inactive') {
                          return (
                            <div className='badge badge-danger'>Inactive</div>
                          )
                        } else if (result.Status == 'Pending') {
                          return (
                            <div className='badge badge-primary'>Pending</div>
                          )
                        }
                      })()}
                  </td>
                  <td>
                  <Link to={`${result.CourseId}/edit`}><button type="button" className="btn btn-primary vminwidth">Update</button></Link>
                  <br/> <br/>
                  <button type="button" className="btn btn-warning vminwidth" onClick={this.Changestatus} data-status={result.Status} data-remove={result.CourseId}>Change Status</button>
                  
                  </td>
                </tr>
             
            )
          })}
                  
                </tbody>
                <tfoot>
                  <tr>
                  <th>Sno</th>
                    <th>Course Title</th>
                    <th>University</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>    
              </div>
              <div id="universitymain_table">       
              
              </div>      
              
            </div>
            {/* /.card-body */}
          </div>
        
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
</div>

   
    </div>

    )
  }
}
