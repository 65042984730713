import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './blog.scss';
import ReactCrop from 'react-image-crop'
import axios from 'axios';
import withRouter from '../../withRouter';
class Update extends Component {
   
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
        filenameval:'',
        // Initially, no file is selected
      selectedFile: null
    }
    
}
// On file select (from the pop up)
onFileChange = event => {
     
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
 
};

async componentDidMount() 
    {
        let idvalue = this.props.params.id;
        

        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getblogid_react.php?id=${idvalue}`).then(function(response) {
         $('#title').val(response.data[0]['Title']);
         $('#description').val(response.data[0]['Description']);
         $('#blogid').val(response.data[0]['BlogID']);
         $('#filename').val(response.data[0]['Blogpic']);
         if(response.data[0]['Blogpic'] == '')
         {
          $('#blogpic').attr('src',`${process.env.REACT_APP_WEBSERVICE_URL}/uploads/image_icon.png`);
         }else
         {
          $('#blogpic').attr('src',`${process.env.REACT_APP_WEBSERVICE_URL}/uploads/`+response.data[0]['Blogpic']);
         }
        
    });
    }
 
  submitHandler = async (event) => {
    event.preventDefault();
    let filenameval='';
    const title = $('#title').val();
    const desc = $('#description').val();
    const id = $('#blogid').val();
    let file =this.state.selectedFile;
    console.log(file);
    if(file === null)
    {
      filenameval = $('#filename').val();
    }
    else
    {
      filenameval = this.state.selectedFile.name;
    }
      var formData = new FormData();
      formData.append('title', title);
      formData.append('desc', desc);
      formData.append('filen', file);
      formData.append('id', id);
      formData.append('filename', filenameval);
      console.log(this.state.selectedFile);
      console.log(id);
    await fetch("http://localhost/immigrations_azure/admin/webservices/updateblog_react.php",{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('Blog Updated Sucessfully');
        $('.error').css("color", "green");
        axios.get(`http://localhost/immigrations_azure/admin/webservices/getblogid_react.php?id=${id}`).then(function(response) {
         $('#title').val(response.data[0]['Title']);
         $('#description').val(response.data[0]['Description']);
         $('#blogid').val(response.data[0]['BlogID']);
         $('#filename').val(response.data[0]['Blogpic']);
         if(response.data[0]['Blogpic'] == '')
         {
          $('#blogpic').attr('src','http://localhost/immigrations_azure/admin/webservices/uploads/image_icon.png');
         }else
         {
          $('#blogpic').attr('src','http://localhost/immigrations_azure/admin/webservices/uploads/'+response.data[0]['Blogpic']);
         }
        });
      }
      else
      {
        $('#result').html(data);
        $('.error').css("color", "red");
      }
    })

}
  render() {
    let idval = this.props.params.id;
    $(function () {
       
        // Summernote
        //$('#summernote').summernote();
        CodeMirror.fromTextArea(document.getElementById("summernote"), {
          mode: "htmlmixed",
          theme: "monokai"
        });
      })
    return (
        <div className='updateblog'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Update Blog</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/blog">Manage Blog</a></li>
            <li className="breadcrumb-item active">Update Blog</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">

        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
              <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Blog Pic</label>
                  <br/>
                  <img id="blogpic" src="" style={{width:"303px",height:"303px"}}></img>
                  <br/>
                  <input type="file" name="pic"  id="pic"  onChange={this.onFileChange} />
                  <input type="hidden" name="filename" id="filename"/>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Title<sup className='validation'>*</sup></label>
                  <input type="text" name="title" className="form-control" id="title" placeholder="Enter Title" required onChange={(event)=>{
                        this.setState({
                          title:event.target.value
                        });
                    }} value={this.state.title}   />
                  <input type="hidden" name="blogid" id="blogid"/>
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description<sup className='validation'>*</sup></label>
                    <textarea className="form-control"  name="description" id="description"  required onChange={(event)=>{
                        this.setState({
                            description:event.target.value
                        });
                    }} value={this.state.description} ></textarea>
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        <div className="col-md-2">

        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}export default withRouter(Update);
