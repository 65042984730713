import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
export default function ListUser() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState([]);

    const {id} = useParams();

    useEffect(() => {
        getUser();
    }, []);

    function getUser() {
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getuserid_react.php?id=${id}`).then(function(response) {
            console.log(response.data[0]['Description']);
            setInputs(response.data);
             $('#fullname').val(response.data[0]['FullName']);
             $('#emailid').val(response.data[0]['EmailId']);
             $('#mobile').val(response.data[0]['Mobile']);
             $('#time').val(response.data[0]['CreatedAt']);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    
    return (
      <div className='updatemarquee'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          {/*<h1>View User</h1>*/}
    </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/users">Manage Users</a></li>
            <li className="breadcrumb-item active">View User</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">
            
        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
            <h4>View User</h4>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Full Name</label>
                  <input type="text" name="fullname" className="form-control" id="fullname" value={inputs.FullName}   readOnly   />
                  <input type="hidden" name="userid" id="userid"/>
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">EmailId</label>
                    <input type="text" className="form-control" value={inputs.EmailId} name="emailid" id="emailid"   readOnly/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Mobile</label>
                    <input type="text" className="form-control" value={inputs.Mobile} name="mobile" id="mobile"  readOnly/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Checkin Time</label>
                    <input type="text" className="form-control" value={inputs.CreatedAt} name="time" id="time" readOnly   />
                </div>
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
              <a href="../../users"><button type="button" className="btn btn-primary">Back</button></a>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-2">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>


       
    )
}
