import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  
var subjecttypeval='';
export default function ListUser() {
    const navigate = useNavigate();
    const TeamData =[];
    const [inputs, setInputs] = useState([]);
    const [stateOptions, setStateValues] = useState([]); 
    const [countryOptions, setCountryValues] = useState([]); 
    const [coursesOptions, setCoursesValues] = useState([]); 
    const [studyOptions, setStudyValues] = useState([]); 
    const [provinceOptions, setProvinceValues] = useState([]); 
    const [universityOptions, setUniversityValues] = useState([]);
    const [subjecttypeOptions, setSubjecttypeValues] = useState([]);
    const {id} = useParams();
    let selval;
    const courseval='';
    //const subjecttypeval='';
    let studylevelval;
    let countryval;
    let provinceval;
    let universityval;
    let profilestatus_val;
    let responsedate;
    const startDate= new Date()  ;
    const [edit_check_date, set_edit_check_date] = useState();
    useEffect(() => {
        getUser();
       // getTeam();
       // getCountry();
       // getStudylevel();
        //getProvince();
        //getUniversity();
        //getCourses();
        //getsubjecttype();
        /*hideapproveddate_row();*/
    }, []);
    function getUser() {
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcourseid_react.php?id=${id}`).then(function(response) {
            
            profilestatus_val=response.data[0]['Status'];
            setInputs(response.data);
             const courseval =response.data[0]['CourseTypeId'];
               subjecttypeval =response.data[0]['SubjectTypeId'];
               countryval =response.data[0]['CountryId'];
               provinceval =response.data[0]['ProvinceId'];
               studylevelval =response.data[0]['StudyLevelId'];
               universityval =response.data[0]['UniversityId'];
           // alert(subjecttypeval);
             $('#courseid').val(response.data[0]['CourseId']);
             $('#CourseName').val(response.data[0]['CourseName']);
             $('#Website').val(response.data[0]['Website']);
             $('#APFee').val(response.data[0]['APFee']);
             $('#City').val(response.data[0]['City']);
             $('#Duolingo').val(response.data[0]['Duolingo']);
             $('#Fees').val(response.data[0]['Fees']);
             $('#StudyType').val(response.data[0]['StudyType']);
             $('#Backlogs').val(response.data[0]['Backlogs']);
             $('#UniversityTransfer').val(response.data[0]['UniversityTransfer']);
             $('#Duration').val(response.data[0]['Duration']);
             $('#Intake').val(response.data[0]['Intake']);
             $('#AdmissionRequirements').val(response.data[0]['AdmissionRequirements']);
             $('#IELTS').val(response.data[0]['IELTS']);
             $('#PTE').val(response.data[0]['PTE']);
             $('#TOEFL').val(response.data[0]['TOEFL']);
             $('#Internship').val(response.data[0]['Internship']);
             $('#Format').val(response.data[0]['Format']);
             $('#Co_op').val(response.data[0]['Co_op']);
             
             
             
              axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getsubjecttypedropdown_id_react.php?CourseTypeId=${courseval}&Subjectypeid=${subjecttypeval}`).then(function(res) {
                console.log(res.data);
                //setSubjecttypeValues(res.data);
                $("#subjecttypeid").html(res.data);
                //console.log(pdsummaryState);    
            });
            axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcoursesdropdown_id_react.php?CourseTypeId=${courseval}`).then(function(res) {
              console.log(res.data);
              //setCoursesValues(res.data);
              $("#coursestypeid").html(res.data);
              //console.log(pdsummaryState);    
          });
          axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getcountry_id_react.php?Countryid=${countryval}`).then(function(res) {
            console.log(res.data);
            //setCountryValues(res.data);
            //console.log(pdsummaryState);   
            $("#countryid").html(res.data); 
        });
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprovince_id_react.php?Provinceid=${provinceval}`).then(function(res) {
          console.log(res.data);
          $("#provinceid").html(res.data); 
          //setProvinceValues(res.data);
          //console.log(pdsummaryState);    
      });
      axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getstudylevel_id_react.php?Studylevelid=${studylevelval}`).then(function(res) {
        console.log(res.data);
        $("#studylevelid").html(res.data); 
        //setStudyValues(res.data);
        //console.log(pdsummaryState);    
    });
    axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getuniversity_id_react.php?UniversityId=${universityval}`).then(function(res) {
      console.log(res.data);
      $("#universityid").html(res.data); 
     // setUniversityValues(res.data);
      //console.log(pdsummaryState);    
  });
        });
        
    }
   
  




    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
    
    
  
    const submitHandler = async (event) => {
        event.preventDefault();
      
        //console.log(this.state.username);
       // console.log(this.state.password);
        const CourseName = $("#CourseName").val();
        const CourseType = $("#CourseType").val();
        const SubjectType =$("#SubjectType").val();
        const StudyLevel = $("#StudyLevel").val();
        const Country =  $("#Country").val();
        const City =$("#City").val();
        const Campus = $("#Campus").val();
        const Fees = $("#Fees").val();
        const StudyType = $("#StudyType").val();
        const Co_op = $("#Co_op").val();
        const PTE = $("#PTE").val();
        const Intake = $("#Intake").val();
        const Backlogs = $("#Backlogs").val();
        const IELTS = $("#IELTS").val();
        const Province = $("#Province").val();
        const University = $("#University").val();
        const APFee = $("#APFee").val();
        const Website = $("#Website").val();
        const Internship = $("#Internship").val();
        const Format = $("#Format").val();
        const TOEFL = $("#TOEFL").val();
        const Duolingo = $("#Duolingo").val();
        const Duration = $("#Duration").val();
        const UniversityTransfer = $("#UniversityTransfer").val();
        const AdmissionRequirements = $("#AdmissionRequirements").val();
        const idval=$('#courseid').val();
      
        if(UniversityTransfer == 'undefined')
        {
          UniversityTransfer='';
        }
        else if( Campus=='undefined')
        {
          Campus='';
        }
        else if( Duration=='undefined')
        {
          Duration='';
        }
        else if( Fees=='undefined')
        {
          Fees='';
        }
        else if( IELTS=='undefined')
        {
          IELTS='';
        }
        else if( PTE=='undefined')
        {
          PTE='';
        }
        else if( TOEFL=='undefined')
        {
          TOEFL='';
        }else if( APFee=='undefined')
        {
          APFee='';
        }
        else if( Co_op=='undefined')
        {
          Co_op='';
        }
        else if( Format=='undefined')
        {
          Format='';
        }else if( Duolingo=='undefined')
        {
          Duolingo='';
        }
        else if( Internship=='undefined')
        {
          Internship='';
        }
        else if( Backlogs=='undefined')
        {
          Backlogs='';
        }	
          
            else if( Website=='undefined')
            {
              Website='';
            }
            else if( Intake=='undefined')
            {
              Intake='';
            }
            else if( AdmissionRequirements=='undefined')
            {
              AdmissionRequirements='';
            }
            else if( Internship=='undefined')
            {
              Internship='';
            }
            else if(StudyType == 'undefined')
            {
              StudyType='';
            }
        
        
          var formData = new FormData();
          formData.append('CourseName', CourseName);
          formData.append('CourseType', CourseType);
          formData.append('SubjectType', SubjectType);
          formData.append('StudyLevel', StudyLevel);
          formData.append('Country', Country);
          formData.append('City', City);
          formData.append('Campus', Campus);
          formData.append('Fees', Fees);
          formData.append('StudyType', StudyType);
          formData.append('Co_op', Co_op);
          formData.append('PTE', PTE);
          formData.append('Intake', Intake);
          formData.append('Backlogs', Backlogs);
          formData.append('IELTS', IELTS);
          formData.append('Province', Province);
          formData.append('APFee', APFee);
          formData.append('University', University);
          formData.append('Website', Website);
          formData.append('Internship', Internship);
          formData.append('Format', Format);
          formData.append('TOEFL', TOEFL);
          formData.append('Duolingo', Duolingo);
          formData.append('Duration', Duration);
          formData.append('UniversityTransfer', UniversityTransfer);
          formData.append('AdmissionRequirements', AdmissionRequirements);
          formData.append('id', idval);
    await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/updatecourse_react.php`,{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }).then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('Course Details Updated Sucessfully');
        $('.error').css("color", "green");
        //$('#linkname').val("");
        //$('#description').val("");
      }
      else
      {
        $('#result').html('Please check the link and description');
        $('.error').css("color", "red");
      }
    })
       /* axios.put(`http://localhost/immigrations_azure/admin/updatemarquee_react.php?id=${id}`, inputs).then(function(response){
            console.log(response.data);
            navigate('/');
        });*/
        
    }
    return (
      <div className='editcourse'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Update Course</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="/courses">Manage Courses</a></li>
            <li className="breadcrumb-item active">Edit Course</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
       
        <div className="col-md-12">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={submitHandler}>
              <div className="card-body">
           
     <div className="row">
            <div className="col-md-6">
            <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Course Name<sup className='validation'>*</sup></label>
                  <input type="text" name="CourseName" className="form-control" id="CourseName" placeholder="Enter Course Name" required onChange={handleChange}  />
                  <input type="hidden" name="courseid" id="courseid" />
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Study Field<sup className='validation'>*</sup></label>
                  <div id="coursestypeid"></div>
                  {/*<select className="form-control vformcolor" name="CourseType" id="CourseType" required onChange={handleChange}>
                  <option value="">Select Course</option>
                  {coursesOptions.map((localState, index) => (
              <option key={index} value={localState.CourseTypeId} defaultValue={courseval || 'Select'}>{localState.CourseType}</option>
            ))}
                      
                </select>*/}
                 
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Country<sup className='validation'>*</sup></label>
                  <div id="countryid"></div>
               {/*<select className="form-control vformcolor" name="Country" id="Country" required  onChange={handleChange}>
               <option value="">Select Country</option>
               {countryOptions.map((localState, index) => (
              <option key={index} value={localState.CountryId} defaultValue={selval || 'Select'}>{localState.Country}</option>
            ))}
                </select>*/}

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City<sup className='validation'>*</sup></label>
                  <input type="text" name="City" className="form-control" id="City" placeholder="Enter City" required onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Campus</label>
                  <input type="text" name="Campus" className="form-control" id="Campus"   onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Tution Fees ($)</label>
                  <input type="number" name="Fees" className="form-control" id="Fees"  onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Delivery</label>
                  <input type="text" name="StudyType" className="form-control" id="StudyType"  onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Co_op</label>
                  <input type="text" name="Co_op" className="form-control" id="Co_op" onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">IELTS</label>
                  <input type="text" name="IELTS" className="form-control" id="IELTS" onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">PTE</label>
                  <input type="text" name="PTE" className="form-control" id="PTE" onChange={handleChange}   />
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Intake</label>
                  <input type="text" name="Intake" className="form-control" id="Intake" onChange={handleChange}   />
                    
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Backlogs</label>
                  <input type="text" name="Backlogs" className="form-control" id="Backlogs"  onChange={handleChange}   />
                </div>
                 
            </div>

            <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Study Field<sup className='validation'>*</sup></label>
                  <div id="studylevelid"></div>
                {/*<select className="form-control vformcolor" name="StudyLevel" id="StudyLevel" required  onChange={handleChange}>
                  <option value="">Select Study Level</option>
                  {studyOptions.map((localState, index) => (
              <option key={index} value={localState.StudyLevelId} defaultValue={selval || 'Select'}>{localState.StudyLevel}</option>
            ))}
                      

                </select>*/}

                
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Study Subject<sup className='validation'>*</sup></label>
                  <div id="subjecttypeid"></div>
                  {/*<select className="form-control vformcolor" name="SubjectType" id="SubjectType" required  onChange={handleChange} >
                  <option value="">Select SubjectType</option>
                  {subjecttypeOptions.map((localState, index) => (
              <option key={index} value={localState.SubjectTypeId} defaultValue={subjecttypeval || 'Select'}>{localState.SubjectType}</option>
                  ))}
                       
                </select>*/}
                 
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Province<sup className='validation'>*</sup></label>
                  <div id="provinceid"></div>
                  {/*<select className="form-control vformcolor" name="Province" id="Province" required  onChange={handleChange} >
                  <option value="">Select Province</option>
                  {provinceOptions.map((localState, index) => (
              <option key={index} value={localState.ProvinceId} defaultValue={selval || 'Select'}>{localState.Province}</option>
            ))}
                      
                </select>*/}

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">University/College<sup className='validation'>*</sup></label>
                  <div id="universityid"></div>
                  {/*<select className="form-control vformcolor" name="University" id="University" required  onChange={handleChange} >
                    <option value="">Select University</option>
                    {universityOptions.map((localState, index) => (
              <option key={index} value={localState.UniversityId} defaultValue={selval || 'Select'}>{localState.UniversityName}</option>
            ))}
                       
                  </select>*/}

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Website</label>
                  <input type="text" name="Website" className="form-control" id="Website"   onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Application Fees</label>
                  <input type="text" name="APFee" className="form-control" id="APFee"  onChange={handleChange}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Format</label>
                  <input type="text" name="Format" className="form-control" id="Format" onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Internship</label>
                  <input type="text" name="Internship" className="form-control" id="Internship" onChange={handleChange}   />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">TOEFL</label>
                  <input type="text" name="TOEFL" className="form-control" id="TOEFL" onChange={handleChange}  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Duolingo</label>
                  
                  <input type="text" name="Duolingo" className="form-control" id="Duolingo" onChange={handleChange}   />
           
                    
                </div>
                 <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Duration (in years)</label>
                    <input type="text" name="Duration" className="form-control" id="Duration" onChange={handleChange}  />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">University/College Transfer</label>
                    <input type="text" name="UniversityTransfer" className="form-control" id="UniversityTransfer" onChange={handleChange}   />
                </div>
              </div>
              <div className='row'>
              <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Admission Requirements</label>
                    <textarea className="form-control" name="AdmissionRequirements" id="AdmissionRequirements"  onChange={handleChange} ></textarea>
                </div>
              </div>
              </div>
                
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
}
