import React, { Component } from 'react'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';
import './blog.scss';
export default class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: []
        };
      }
      async componentDidMount() 
    {
      $(".blog").addClass("active");
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getblog_react.php`).then(res => 
        {
          $('#blog_table').html(res.data);
          $("#blogtable_ajax").DataTable({
            "columnDefs": [
              { "width": "20%", "targets": 1 }
            ],
            
              "pageLength": 100
            
          });
          $("#blogtable").hide();
          
       // this.setState({data: res.data});
           });
    }
    Changestatus = async (event) => {
      event.preventDefault();
      const changeId = event.target.dataset.remove;
      const statusvalue = event.target.dataset.status;
      console.log(changeId);
      console.log(statusvalue);
        var formData = new FormData();
        formData.append('id', changeId);
        formData.append('status', statusvalue);
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/Changeblogstatus_react.php`,{
          method:'POST',
          headers: new Headers({
             // 'Content-Type': 'application/x-www-form-urlencoded',
     }),
     body: formData,
      }) .then((response) => response.json())
      .then((data) => {
        
        if(data == 'success')
        {
          $('#result').html('Status Updated Sucessfully');
          $('.error').css("color", "green");
          window.location.href ='Blog';
         
          
        }
        else
        {
          $('#result').html('Update failed');
          $('.error').css("color", "red");
        }
      })
  
  }
  render() {
   // const { hits } = this.state;  
   // console.log(data)
    $(function () {
        //$("#blogtable").dataTable().fnDestroy();
        $("#blogtable").DataTable();
       /* $("#blogtable").DataTable({
          "responsive": true
          
        });*/
        $(".dataTables_empty").hide();
      });
    return (
     <div className='blog'>
      <Header />
      <Sidebar/>
      
    <div className='content-wrapper'>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Manage Blog</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
            <li className="breadcrumb-item active">Blog</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
            <p id="result" className="error"></p>
              <h3 className="card-title"><a href='../addblog'><button className='btn btn-info float-right'>Add Blog</button></a></h3>
            </div>
            {/* /.card-header */}
            <div className="card-body" id='blog_table'>
              <table id="blogtable" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.data.map((result) => {
            return (
             
                 <tr>
                  <td>{result.BlogID}</td>
                  <td>{result.Title}</td>
                  <td>{result.Description.substring(0, 7)+'.....'}</td>
                  
                  <td>
                                  {(() => {
                        if (result.Status == 1) {
                          return (
                            <div className='badge badge-success'>Active</div>
                          )
                        } else if (result.Status == 0) {
                          return (
                            <div className='badge badge-danger'>Inactive</div>
                          )
                        } else {
                          return (
                            <div>catch all</div>
                          )
                        }
                      })()}
                  </td>
                  <td>
                  <Link to={`${result.BlogID}/edit`}><button type="button" className="btn btn-primary vminwidth">Update</button></Link>
                  <br/> <br/>
                  <button type="button" className="btn btn-warning vminwidth" onClick={this.Changestatus} data-status={result.Status} data-remove={result.BlogID}>Change Status</button>
                  </td>
                </tr>
             
            )
          })}
                  
                </tbody>
                <tfoot>
                  <tr>
                     <th>Sno</th>
                     <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                     <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            {/* /.card-body */}
          </div>
        
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
</div>

   
    </div>

    )
  }
}
