import React, { Component } from 'react'
import Sidebar from './components/sidebar/Sidebar'
import Header from './components/header/Header'
import axios from 'axios';
import $ from 'jquery';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


 
//Datatable Modules
import DataTable from 'datatables.net';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import './index.css';
let result;
let statusval;
let statusvalue;
let result_case;
let newdata;
let newdata_val;
let newdata_bar;
let obj;
let visatype;
let consultant;
let status;
let year;
let count =1;
let title;
let subtitle_val;
let val_dash;
export default class Timelinedashboard extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    const year = (new Date()).getFullYear();
    this.years = Array.from(new Array(4),(val, index) => year - index);
    this.state = {
     
          TeamData: [],
          chartvalue: [],
          data: [],
          serachdata: [],
          loading: 'false',
    };
    
    
    
    
}
  async componentDidMount() 
    {
      $(".timelineval").addClass("active");
      this.setState({
        loading: 'true'
      });
      var loading = $('.loader');
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/timelinedashboardvalues_react.php`).then(res => 
        {
        //this.setState({data: res.data});
         result = res.data.split('&');
         $('#inprogress').html(result[0]);
         $('#awaiting').html(result[1]);
         $('#approved').html(result[2]);
         $('#duration').html(result[3]);
           });
           await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/gettimeline_defaultvisa_reactphp.php`,{
            method:'POST',
            headers: new Headers({
               // 'Content-Type': 'application/x-www-form-urlencoded',
       }),
       body: 'PR',
        }) .then((response) => response.json())
        .then((data) => {
          //console.log(data);
         // var obj = $.parseJSON(data);
		    newdata = $.map(data.year, function(el, index) {
		    return {
				name: el,
				y: parseInt(data['candidatescount'][index])
				};
				});
        this.highChartsRender();
        }).finally(() => {
          loading.hide();
      });
        
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/gettimeline_visateabledata_react.php`).then(res => 
          {
          //this.setState({serachdata: res.data});
          $('#searchdata_table').html(res.data);
          $("#pr_usertable_data").DataTable({
            "pageLength": 100,
            processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]

          });
             });
             
        
           axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteam_react.php`).then(response => {
            
            this.setState({
            TeamData: response.data
            });
            });
            axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/timelinedashboardvalues_react.php`).then(response => {
            
             // alert(response.data);
             const  succ= response.data+'%'
              $("#successval").html(succ);
              });
            /*axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getprvisa_react.php`).then(response => {
              console.log(response.data);
              
              
              });*/
            
                 
           
    }
    highChartsRender() {
      Highcharts.chart({
        chart: {
          height: 500,
          type: 'column',
          renderTo: 'visa-composition'
         },
       title: {
           text: 'Company Performance Report'
       },

       subtitle: {
           text: "All Profile Status Report"
       },
     xAxis: {
                  labels: {
                    style: {
                        fontSize: '16px',
                      
                    }
                },
                  type: 'category',
                       },
       yAxis: {
           title: {
               text: 'Number of days'
           }
       },

       plotOptions: {
         column: {
           minPointLength: 3
         },
           series: {
             dataLabels: {
                       enabled: true,
                       style: {
                              
                        fontSize:14,
                    },
                   },
               label: {
                   connectorAllowed: false
               },
               pointWidth: 30,
               color: '#22ca0f',
               
               
           }
       },
       series: [{
           name: "Profile Status",
           data:newdata          
       }],

       responsive: {
           rules: [{
               condition: {
                   maxWidth: 500
               },
               chartOptions: {
                   legend: {
                       layout: 'horizontal',
                       align: 'center',
                       verticalAlign: 'bottom'
                   }
               }
           }]
       }
        });
    }
    visaData = async(event) => {
      event.preventDefault();
      this.setState({selectValue: event.target.value,loading: 'true'});
       visatype=$('#visatype').val();
       consultant=$('#consultant').val();
       status=$('#status').val();
       year=$('#year').val();
       
      var formData = new FormData();
      formData.append('visatype', visatype);
      formData.append('consultant', consultant);
      formData.append('status', status);
      formData.append('year', year);
      //alert(visatype);
      
      var loading = $('.loader');
      await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/gettimeline_searchdata_react.php`,{
        method:'POST',
        headers: new Headers({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: formData,
        }) .then((response) => response.json())
          .then((data) => {
          console.log(data);
          if(status==2)
          statusvalue='Approved';
          if(status==6)
          statusvalue='Awaiting Decision';
          if(status==1)
          statusvalue='Inprogress';
          if(status==7)
          statusvalue='ITA Received';
        // var obj = $.parseJSON(data);
        if(year =='All' && status!='All')
        {
          statusval = statusvalue;
          title="Year";	
          subtitle_val="Yearly Performance Report";			
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
        }
        else if(year !='All' && status!='All')
        {
          statusval = statusvalue;
          title="Year";		
          subtitle_val="Yearly Performance Report";			
          newdata_val = $.map(data.month, function(el, index) {
							return {
										name: el,
										y: parseInt(data['successcount'][index])
									};
						});
        }
        else if(year !='All' && status=='All')
         {
          statusval = 'All Profile Status';
          title="Profile Status";
          subtitle_val="All Profile Status Report";
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
         else if(year =='All' && status=='All')
         {
          statusval = 'All Profile Status';
          title="Profile Status";
          subtitle_val="All Profile Status Report";
          newdata_val = $.map(data.year, function(el, index) {
            return {
                name: el,
                y: parseInt(data['candidatescount'][index])
                };
                });
         }
         
        })
//this.highchartsnew();
            Highcharts.chart({
              chart: {
                height: 500,
                type: 'column',
                renderTo: 'visa-composition'
              },
            title: {
                text: 'Company Performance Report'
            },

            subtitle: {
                text: subtitle_val
            },
            xAxis: {
                          labels: {
                            style: {
                                fontSize: '16px',
                              
                            }
                        },
                        title: {
                          text: title
                            },
                        type: 'category',
                            },
            yAxis: {
                title: {
                    text: 'Number of Days'
                }
            },

            plotOptions: {
              column: {
                minPointLength: 3
              },
                series: {
                  dataLabels: {
                            enabled: true,
                            style: {
                              
                              fontSize:14,
                          },
                        },
                    label: {
                        connectorAllowed: false
                    },
                    pointWidth: 30,
                    color: '#22ca0f',
                    
                    
                }
            },
            series: [{
                name: statusval,
                data:newdata_val          
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
              }
              })
              await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/gettimeline_tabledata_searchdata_react.php`,{
                method:'POST',
                headers: new Headers({
                // 'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: formData,
                }).then((response) => response.json())
                .then((data) => {
                console.log(data);
                  if(data == 'No Data Avaliable')
                    {
                          $("#searchdata_table").hide();
                    }
                    else{
                     // $("#searchdata_table").show();
                     $('#searchdata_table').html('');
                      $('#searchdata_table').html(data);
                      $("#pr_usertable_data").DataTable({
                        "pageLength": 100,
                        processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
                      });
                      //this.setState({serachdata: data});
                    }
             }).finally(() => {
              loading.hide();
          });
                

                await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getdashboardvalues_searchdata_react.php`,{
                  method:'POST',
                  headers: new Headers({
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                  }),
                  body: formData,
                  }).then((response) => response.json())
                  .then((data) => {
                  console.log(data);
                  result = data.split('&');
                  $('#inprogress').html(result[0]);
                  $('#awaiting').html(result[1]);
                  $('#approved').html(result[2]);
                  $('#duration').html(result[3]);
                    
               })
                  

                  await fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/getcases_searchdata_react.php`,{
                    method:'POST',
                    headers: new Headers({
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: formData,
                    }) .then((response) => response.json())
                      .then((data) => {
                        console.log(data);
                        //alert(data);
                        result_case = data.split('&');
                        $('#total').html(result_case[0]);
                        $('#active').html(result_case[1]);
                        $('#fail').html(result_case[2]);
                       
                       
                    })

                  

      } 
     
      
       
      
  render() {
    
    return (
      <div>
        <Header />
        <Sidebar />
        <div class="loader"></div>
  <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Timeline Dashboard</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section className="content">
      <div className="container-fluid">
        
        <div className='row'>
        {/*<div className="col-lg-3 col-6">
            <div className="small-box bg-fuchsia">
              <div className="inner">
               <span> <h3 id='duration'></h3> days</span>
               
                <h5 id="h5_style">Submission</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              
            </div>
          </div>*/}
          <div className="col-lg-3 col-6">
            <div className="small-box bg-fuchsia">
              <div className="inner">
               <span> <h3 id='duration'></h3> days</span>
               
                <h5 id="h5_style">Submission</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
             {/* <Link to="/candidates/inprogresst" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>*/}
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-fuchsia">
              <div className="inner">
               <span> <h3 id='inprogress'></h3> days</span>
               
                <h5 id="h5_style">Inprogress</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
             {/* <Link to="/candidates/inprogresst" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>*/}
            </div>
          </div>
          {/*<div className="col-lg-3 col-6">
            <div className="small-box bg-gray">
              <div className="inner">
                <h3 id='submission'></h3>
                <h5 id="h5_style">Submission</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <Link to="/candidates/fail" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
            </div>
    </div>*/}
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                
                <span> <h3 id='awaiting'></h3> days</span>
                <h5 id="h5_style">Awaiting Decision</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
             {/* <Link to="/candidates/awaiting" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>*/}
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                
                <span> <h3 id='approved'></h3> days</span>
                <h5 id="h5_style">Approved</h5>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              {/*<Link to="/candidates/success" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>*/}
            </div>
          </div>
          
        </div>
        
        <div className='row'>
          <section className="col-lg-12 connectedSortable">
            <div className="card">
                  <div className="card-header">
                    <div className='row'>
                    <div className="col-lg-3">
                          <label>File Status</label>
                        <select className='form-control' name="status" id="status" value={this.state.value} onChange={this.visaData}>
                        <option value="All">All File Status</option>
                        <option value='2'>Approved & Closed</option>  
                        <option value='6'>Awaiting Decision</option>  
                        {/*<option value='7'>ITA Received</option>
                        <option value='3'>Customer Quit</option> */}
                        <option value='1'>Inprogress</option>  
                        {/*<option value='4'>On Hold</option>  
                        <option value='5'>Rejected</option>  */}
                        </select>
                        </div>
                        <div className="col-lg-3">
                          <label>Visa Type</label>
                        <select className='form-control' name='visatype' id='visatype' value={this.state.value} onChange={this.visaData}>
                        <option value="All" selected>All Visas </option>
                          <option value="PR" >Permanent Residency</option>
                          <option value="LMIAs">LMIA </option>
                          <option value="Misc">Misc Services</option>
                          <option value="PNP">PNPs/Job Offers/RNIPs </option>
                          <option value="Dependent">SOWP</option>
                          <option value="Student">Student Visa </option>
                          <option value="Super">Super Visa </option>
                          <option value="Visitor">Visitor Visa </option>
                          <option value="Work Permit">Work Permit Visa </option>
                          <option value="Startup">Start-up Visa</option>
                          <option value="Spousal PR">Spousal PR</option>
                        </select>
                        </div>
                        <div className="col-lg-3">
                          <label>Consultant</label>
                        <select className='form-control' name="consultant" id="consultant" value={this.state.value} onChange={this.visaData}>
                        <option value="">Select Consultant</option>
                                    {this.state.TeamData.map((e, key) => {  
                          return <option key={key} value={e.Teamid}>{e.Name}</option>;  
                          })}      
                        </select>
                        </div>
                        
                        <div className="col-lg-3">
                          <label>Year</label>
                          <select className='form-control' name="year" id="year" value={this.state.value} onChange={this.visaData}>
                          <option value="All">All Years</option>
                          {
                            this.years.map((year, index) => {
                              return <option key={`year${index}`} value={year}>{year}</option>
                            })
                          }
                          </select>
                        
                        </div>
                    </div>
                    
                  </div>
                  <div className="card-body">
                  <div id="visa-composition">
  	              </div>
                  <div id='searchdata_table'>
                    </div>
                    <div>
                  
                  </div>

                  </div>
                  
            </div>
          </section>
        </div>
        
      </div>
    </section>
  </div>
</div>

    )
  }
}
