import React, { Component } from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery';
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'

import './users.scss';
import ReactCrop from 'react-image-crop'
export default class Adduser extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props){
    super(props);
    this.state = {
        title:'',
        description:'',
        // Initially, no file is selected
      selectedFile: null
    }
    
}
// On file select (from the pop up)
onFileChange = event => {
     
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
 
};
  submitHandler = async (event) => {
    event.preventDefault();
    //console.log(this.state.username);
   // console.log(this.state.password);
    const title = this.state.title;
    const desc = this.state.description;
    const file =this.state.selectedFile;
    const filename = this.state.selectedFile.name;
      var formData = new FormData();
      formData.append('title', title);
      formData.append('desc', desc);
      formData.append('filen', file);
      formData.append('filename', filename);
      console.log(this.state.selectedFile);
    await fetch("http://localhost/immigrations_azure/admin/webservices/insertblog_react.php",{
        method:'POST',
        headers: new Headers({
           // 'Content-Type': 'application/x-www-form-urlencoded',
   }),
   body: formData,
    }) .then((response) => response.json())
    .then((data) => {
      //console.log('Success:', data);
      if(data == 'success')
      {
        $('#result').html('Blog Added Sucessfully');
        $('.error').css("color", "green");
        $('#title').val("");
        $('#description').val("");
        $('#pic').val("");
      }
      else
      {
        $('#result').html('Please check the link and description');
        $('.error').css("color", "red");
      }
    })

}
  render() {
    $(function () {
        // Summernote
        //$('#summernote').summernote();
        CodeMirror.fromTextArea(document.getElementById("summernote"), {
          mode: "htmlmixed",
          theme: "monokai"
        });
      })
    return (
      <div className='addblog'>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-4">
          <h1>Add User</h1>
        </div>
        <div className='col-sm-4'>
        <p id="result" className="error"></p>
        </div>
        <div className="col-sm-4">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="blog">Manage Users</a></li>
            <li className="breadcrumb-item active">Add User</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        {/* left column */}
        <div className="col-md-2">
        </div>
        <div className="col-md-8">
          {/* jquery validation */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">(*) <small>Required Fields</small></h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form id="quickForm" onSubmit={this.submitHandler}>
              <div className="card-body">
            { /*<div className="form-group">
                  <label htmlFor="exampleInputEmail1">Blog Pic<sup className='validation'>*</sup></label>
                  <br/>
                  <input type="file" name="pic"  id="pic"  required  onChange={this.onFileChange}   />
    </div>*/}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Candidate Name<sup className='validation'>*</sup></label>
                  <input type="number" name="name" className="form-control" id="name" placeholder="Enter Name" required onChange={(event)=>{
                        this.setState({
                            name:event.target.value
                        });
                    }} value={this.state.name}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Phone Number<sup className='validation'>*</sup></label>
                  <input type="text" name="phn" className="form-control" id="phn" placeholder="Enter Phone Number" required onChange={(event)=>{
                        this.setState({
                            phn:event.target.value
                        });
                    }} value={this.state.phn}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email ID<sup className='validation'>*</sup></label>
                  <input type="text" name="email" className="form-control" id="email" placeholder="Enter Emailid" required onChange={(event)=>{
                        this.setState({
                            email:event.target.value
                        });
                    }} value={this.state.email}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Country</label>
                  <input type="text" name="country" className="form-control" id="country" placeholder="Enter Country"  onChange={(event)=>{
                        this.setState({
                            country:event.target.value
                        });
                    }} value={this.state.country}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">State</label>
                  <input type="text" name="stateval" className="form-control" id="stateval" placeholder="Enter State"  onChange={(event)=>{
                        this.setState({
                            stateval:event.target.value
                        });
                    }} value={this.state.stateval}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City</label>
                  <input type="text" name="city" className="form-control" id="city" placeholder="Enter City" onChange={(event)=>{
                        this.setState({
                            city:event.target.value
                        });
                    }} value={this.state.city}  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Type Of Visa<sup className='validation'>*</sup></label>
                  <select name="typeofvisa" id="typeofvisa" value={this.state.value} className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
                      <option value="">Select Visa Type</option>
                      <option value="PR">PR Visa</option>
                      <option value="Work">Work Permit Visa</option>
                      <option value="Visitor">Visitor Visa</option>
                      <option value="super">Super Visa</option>
                      <option value="student">Student Visa</option>
                      <option value="pnp">PNPs</option>
                      <option value="lmia">LMIA</option>
                    </select>
                </div>
                {/*<div className="form-group">
                  <label htmlFor="exampleInputPassword1">Description</label>
                  <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                </div>*/}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Assigned Consultant<sup className='validation'>*</sup></label>
                  
                    <select name="assignedconsultant" id="assignedconsultant"  required value={this.state.value} className="form-control vformcolor labelstyle" 
        onChange={this.handleChange} >
                      <option value="">Select Consultant</option>
                      <option value="Ajya">Ajya</option>
                      <option value="Chetan">Chetan</option>
                      <option value="Anusha">Anusha</option>
                      <option value="Sabreen">Sabreen</option>
                     
                    </select>
                </div>
                {/* <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Description<sup className='validation'>*</sup></label>
                    <textarea className="form-control" name="description" id="description" required onChange={(event)=>{
                        this.setState({
                            description:event.target.value
                        });
                    }} value={this.state.description}></textarea>
                </div>*/}
                {/*<div className="form-group mb-0">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                    <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                  </div>
                </div>*/}
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          {/* /.card */}
        </div>
        <div className="col-md-2">
        </div>
        {/*/.col (left) */}
        {/* right column */}
        <div className="col-md-6">
        </div>
        {/*/.col (right) */}
      </div>
      {/* /.row */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

      </div>
    )
  }
}
