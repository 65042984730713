import React, { Component } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Header from '../../components/header/Header'
import axios from 'axios';
import $ from 'jquery';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


 
//Datatable Modules
import DataTable from 'datatables.net';
import './export.scss';
let consultant;
export default class Export extends Component {
    static propTypes = {
        prop: PropTypes
      }
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          TeamData: []
        };
        
      }
      async componentDidMount() 
    {
           axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getteam_react.php`).then(response => {
            
            this.setState({
            TeamData: response.data
            });
            })
           
    }
    
  visaData = (event) => {
    event.preventDefault();
     consultant=$('#consultant').val();
     alert(consultant);
    var formData = new FormData();
    formData.append('consultant', consultant);
     fetch(`${process.env.REACT_APP_WEBSERVICE_URL}/export.php`,{
        method:'POST',
        headers: new Headers({
            // 'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: formData,
            }).then((response) => response.json())
            .then((data) => {
            console.log(data);
              if(data == 'No Data Avaliable')
                {
                      $("#searchdata_table").hide();
                }
                else{
                 // $("#searchdata_table").show();
                 $('#searchdata_table').html('');
                  $('#searchdata_table').html(data);
                  $("#pr_usertable_data").DataTable({
                    "pageLength": 100
                  });
                  //this.setState({serachdata: data});
                }
         })
    } 
  render() {
   // const { hits } = this.state;  
   // console.log(data)
   
    return (
     <div className='export'>
      <Header />
      <Sidebar/>
      
    <div className='content-wrapper'>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Export</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
            <li className="breadcrumb-item active">Export</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
            <div class="col-lg-3" style={{display: "inline-block"}}>
                        <select className='form-control' name="consultant" id="consultant" value={this.state.value} onChange={this.visaData}>
                        <option value="">Select Consultant</option>
                                    {this.state.TeamData.map((e, key) => {  
                          return <option key={key} value={e.Teamid}>{e.Name}</option>;  
                          })}      
                        </select>
                        
                        </div>
            <div className="col-lg-3" style={{display: "inline-block"}}>           
                    
                    <h3 className="card-title"><a href='../addmarquee'><button className='btn btn-info'>Export Excel Sheet</button></a></h3>
            </div>
            </div>
            {/* /.card-header */}
            <div className="card-body" id='searchdata_table'>
             
            </div>
            {/* /.card-body */}
          </div>
        
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
</div>

   
    </div>

    )
  }
}
