import React, { Component } from 'react'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import $ from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';
import './users.scss';
export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: []
        };
      }
      async componentDidMount() 
    {
      $(".users").addClass("active");
        axios.get(`${process.env.REACT_APP_WEBSERVICE_URL}/getusers_react.php`).then(res => 
        {
        //this.setState({data: res.data});
        $('#candidate_table').html(res.data);
            $("#usertable_ajax").DataTable(
              {
                "pageLength": 100
              }
            );
            $("#usertble").hide();
           });
    }
  render() {
   // const { hits } = this.state;  
   // console.log(data)
    $(function () {
        //$("#usertble").dataTable().fnDestroy();
        $("#usertble").DataTable();
        $(".dataTables_empty").hide();
      });
    return (
     <div className='users'>
      <Header />
      <Sidebar/>
      
    <div className='content-wrapper'>
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Manage Users</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
          <div className="card-header">
            <p id="result" className="error"></p>
             {/* <h3 className="card-title"><a href='adduser'><button className='btn btn-info float-right'>Add User</button></a></h3>*/}
            </div>
            <div className="card-body" id="candidate_table">
              <table id="usertble" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sno</th>
                    <th>User Name</th>
                    <th>Email id</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.data.map((result) => {
            return (
             
                 <tr>
                  <td>{result.UserId}</td>
                  <td>{result.FullName}</td>
                  <td>{result.EmailId}</td>
                  <td>{result.Mobile}</td>
                  
                  <td>
                                  {(() => {
                        if (result.Status == 1) {
                          return (
                            <div className='badge badge-success'>Active</div>
                          )
                        } else if (result.Status == 0) {
                          return (
                            <div className='badge badge-danger'>Inactive</div>
                          )
                        } else {
                          return (
                            <div>catch all</div>
                          )
                        }
                      })()}
                  </td>
                  <td>
                  <Link to={`${result.UserId}/view`}><button type="button" className="btn btn-success vminwidth">View</button></Link>
                  
                  </td>
                </tr>
             
            )
          })}
                  
                </tbody>
                <tfoot>
                  <tr>
                  <th>Sno</th>
                    <th>User Name</th>
                    <th>Email id</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            {/* /.card-body */}
          </div>
        
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
</div>

   
    </div>

    )
  }
}
